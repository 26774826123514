import { CertificationState } from "./reducer"
import { createSelector } from "reselect"

export const getCertifications = (state: { certifications: CertificationState }) => state.certifications

export const getCertificationBySlug = (state: { certifications: CertificationState }) => (slug: string) => state.certifications.categoriesBySlug[slug]

export const getCertificationCurrentItem = createSelector(getCertifications, ({ currentItem }) => {
  return currentItem;
})
