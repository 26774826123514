import { Component } from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"

class TableBlock extends Component {
  render() {
    const { block, contentState } = this.props
    const entity = block.getEntityAt(0)

    if (entity) {
      const data = contentState.getEntity(entity).getData()
      return ReactHtmlParser(data.element)
    }
    return null
  }
}

TableBlock.propTypes = {
  block: PropTypes.object,
  contentState: PropTypes.object,
}
export default TableBlock
