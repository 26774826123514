import { AxiosInstance, CancelToken } from "axios"
import client from "./client"
import { configuratorEndpoints } from "./endpoints/configuratorEndpoints"
import { documentsEndpoints } from "./endpoints/documentsEndpoints"
import { itemsEndpoints } from "./endpoints/itemsEndpoints"

export const defaultClient = client()

export const api = (client = defaultClient) => {
  return {
    ...documentsEndpoints(client),
    ...itemsEndpoints(client),
    ...configuratorEndpoints(client),
    ...endpoints(client),
  }
}

export type ApplicationContext = {
  user?: {
    upn?: string
  }
}

type EndpointType = {
  applicationContext: ApplicationContext | string
  opfItem: any
  opfItems: any
  pageSize: number
  continuationToken: string
}

const endpoints = (client: AxiosInstance) => {
  return {
    config: {
      // 👤  methods for fetching configs
      getJiraLink: () => client.get("/config/jira-approval-url"),
      getFeatureSwitches: () => client.get("/config/feature-switches"),
    },
    user: {
      // ...userRoutes
      // 👤  methods for fetching relevant user data
      getUserProfile: () => client.get("userprofile"),
      getRoles: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("roles/get", { applicationContext }),
      checkAuthenticationStatus: (_: unknown, cancelToken: CancelToken) =>
        client.get("healthcheck/authenticationstatus", { cancelToken }),
    },
    serviceLines: {
      // methods for servicelines data
      getServiceLines: ({
        language,
        fallbackLanguage,
      }: {
        language: string
        fallbackLanguage: string
      }) => client.post("servicelines/get", { language, fallbackLanguage }),
    },
    locale: {
      // 🌐  methods for internationalization
      getLanguages: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("languages/get", { applicationContext }),
    },
    audits: {
      // 🕙  methods for getting audit information about items or configurations in general
      getAudits: (
        { applicationContext, opfItem, pageSize }: EndpointType,
        cancelToken: CancelToken
      ) => client.post("audit/get", { applicationContext, opfItem, pageSize }, { cancelToken }),
      getAllAudits: (
        { applicationContext, pageSize, continuationToken }: EndpointType,
        cancelToken: CancelToken
      ) =>
        client.post(
          "audit/getall",
          { applicationContext, pageSize, continuationToken },
          { cancelToken }
        ),
    },
    favorites: {
      // ⭐  methods for handling the current user's favorite items
      getUserFavorites: (
        { applicationContext }: { applicationContext: ApplicationContext },
        cancelToken: CancelToken
      ) => client.post("favorites/get", { applicationContext }, { cancelToken }),
      addToFavorites: ({ applicationContext, opfItem }: EndpointType) =>
        client.post("favorites/add", { applicationContext, opfItem }),
      removeFromFavorites: ({ applicationContext, opfItem }: EndpointType) =>
        client.post("favorites/remove", { applicationContext, opfItem }),
      updateFavorites: ({ applicationContext, opfItems }: EndpointType) =>
        client.post("favorites/update", { applicationContext, opfItems }),
    },
    masterData: {
      getSearchFilters: (
        { applicationContext }: { applicationContext: ApplicationContext },
        cancelToken: CancelToken
      ) => client.post("masterdata/getsearchfilters", { applicationContext }, { cancelToken }),
    },
    search: {
      // 🔍 methods for searching through the OPF (including all frameworks)
      getSearchResults: (
        {
          applicationContext,
          searchContext,
        }: { applicationContext: ApplicationContext; searchContext: string },
        cancelToken: CancelToken
      ) =>
        client.post(
          "search/getsearchresults",
          { applicationContext, searchContext },
          { cancelToken }
        ),
    },
    levels: {
      // 🌳  hierarchy level data (countries, account, global accounts, country accounts & sites)
      getAccounts: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("accounts/get", { applicationContext }),
      getCountries: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("countries/get", { applicationContext }),
      getGlobalAccounts: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("globalaccounts/get", { applicationContext }),
      getSites: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("sites/get", { applicationContext }),
      // 'all' requests are used to build registries of configurator data (eg. for showing tag names in OpfItem meta data)
      getAllAccounts: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("accounts/getall", { applicationContext }),
      getAllCountries: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("countries/getall", { applicationContext }),
      getAllGlobalAccounts: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("globalaccounts/getall", { applicationContext }),
      getAllSites: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("sites/getall", { applicationContext }),
    },
    drafts: {
      getMyDrafts: ({ applicationContext }: { applicationContext: ApplicationContext }) =>
        client.post("drafts/my-drafts", { applicationContext }),
    },
    home: {
      getHomepage: ({applicationContext}: { applicationContext: ApplicationContext }) =>
        client.post("homepage/get", { applicationContext }),
    },
    news: {
      getNews: ({applicationContext, newsId}: { applicationContext: ApplicationContext, newsId: string }) =>
        client.post("news/get", { applicationContext, newsId: newsId }),
      createNews: ({applicationContext, ...rest}: { applicationContext: ApplicationContext }) =>
        client.post("news/create", { applicationContext, ...rest }),
      deleteNews: ({applicationContext}: { applicationContext: ApplicationContext }) =>
        client.post("news/delete", { applicationContext }),
      getAllNews: ({applicationContext}: { applicationContext: ApplicationContext }) =>
        client.post("news/getall", { applicationContext }),
    },
    opfCommunity: {
      getAllOpfCommunity: ({applicationContext}: { applicationContext: ApplicationContext }) =>
        client.post("opfcommunity/getall", { applicationContext }),
    },
    certifications: {
      getCategories: ({applicationContext}: { applicationContext: ApplicationContext }) =>
        client.post("opfcertificationitems/getcategories", { applicationContext }),
      getCertificationByid: ({id, applicationContext}: { id: string; applicationContext: ApplicationContext }) =>
        client.post('opfcertificationitems/getbyid', {id, applicationContext}),
      getCertificationBySlug: ({slug, applicationContext}: { slug: string; applicationContext: ApplicationContext }) =>
        client.post('opfcertificationitems/getbyslug', {slug, applicationContext}),
      createCategory: ({applicationContext, ...rest}: { applicationContext: ApplicationContext }) =>
        client.post('opfcertificationitems/addcategory', {applicationContext, ...rest}),
      createCertification: ({applicationContext, ...rest}: { applicationContext: ApplicationContext }) =>
        client.post('opfcertificationitems/addcertification', {applicationContext, ...rest}),
      deleteFile:({applicationContext, ...rest}: { applicationContext: ApplicationContext }) =>
        client.post('opfcertificationitems/delete', {applicationContext, ...rest}),
    },
    uniqNumberGenerator: {
      getUniqNumber: ({applicationContext, ...rest}: { applicationContext: ApplicationContext }) =>
        client.post("uniquennumbergenerator/get", { applicationContext, ...rest }),
      saveUniqNumber: ({applicationContext, ...rest}: { applicationContext: ApplicationContext }) =>
        client.post("uniquennumbergenerator/save", { applicationContext, ...rest }),
    },
    statistics: {
      getStatistics: ({applicationContext}: { applicationContext: ApplicationContext }) =>
        client.post("statistics/get", { applicationContext }),
    }
  }
}

export default api
