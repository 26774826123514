import { createAsyncAction } from "../utils"
import { api as externalApi } from "../../services/api"
import types from "./types"

const api = externalApi()

export const fetchUniqNumberGenerator = createAsyncAction(types.FETCH_UNIQ_NUMBER, api.uniqNumberGenerator.getUniqNumber)

export const saveUniqNumber = createAsyncAction(types.SAVE_UNIQ_NUMBER, api.uniqNumberGenerator.saveUniqNumber)

export const resetNumberValue = () => (dispatch: any) => {
  dispatch({
    type: types.RESET_NUMBER_VALUE,
  })
}
