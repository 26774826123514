import { produce } from "immer"
import types, { UniqNumberState } from "./types"

export const initialState: UniqNumberState = Object.freeze({
  loading: false,
  filters: {
    opfLocalizations: [],
    documentTypes: [],
    functions: [],
  }
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_UNIQ_NUMBER.REQUEST:
        writableState.loading = true
        writableState.error = false
        break
      case types.FETCH_UNIQ_NUMBER.SUCCESS:
        writableState.filters = action.payload?.filters || []
        writableState.number = action.payload?.number
        writableState.loading = false
        writableState.error = false
        break
      case types.FETCH_UNIQ_NUMBER.FAILURE:
        writableState.loading = false
        writableState.error = true
        break
      case types.RESET_NUMBER_VALUE:
        writableState.number = undefined
        break
      default:
        return writableState
    }
    return writableState
  })
}
