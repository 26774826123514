import { ChangeEvent, useState } from "react"
import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"

import { bindActions, bindSelectors } from "src/store/utils"
import actions from "src/store/actions"
import selectors from "src/store/selectors"
import { trimSlug } from "src/components/helpers"
import Loading from "src/components/Loading"

import "./style.less"
import { ImageEditorPlugin, ImagePluginTheme } from "@draft-js-plugins/image"
import useAction from "src/hooks/useAction"
import { useLocation } from "react-router-dom"
import { GetSetEditorState } from "@draft-js-plugins/editor"

type ImageUploadButtonProps = {
  theme: { buttonWrapper: string; button: string }
  imagePlugin: ImageEditorPlugin
  setEditorState: GetSetEditorState["setEditorState"]
  getEditorState: GetSetEditorState["getEditorState"]
}

export default function ImageUploadButton(props: ImageUploadButtonProps) {
  const [fileValue, setFileValue] = useState("")

  const { imagePlugin, setEditorState, getEditorState } = props

  const getDocumentBySlug = useSelector(selectors.documentsSelectors.getDocumentBySlug)
  const isUploadingRteFile = useSelector(selectors.documentsSelectors.isUploadingRteFile)
  const uploadFile = useAction(actions.documentsActions.uploadRteFile)
  const location = useLocation()

  const handleInsertImage = (event: ChangeEvent<HTMLInputElement>) => {
    const editorState = getEditorState()
    const slug = trimSlug(location.pathname)
    const opfDocument = getDocumentBySlug(slug)

    uploadFile({ opfDocument, file: event.target.files?.[0] }).then(
      (result: { media: { url: string } }) => {
        setEditorState(imagePlugin.addImage(editorState, result.media.url, {}))
      }
    )
    setFileValue("")
  }

  return (
    <div className={props.theme.buttonWrapper} onMouseDown={(event) => event.preventDefault()}>
      <label className={props.theme.button} role="button" style={{ display: "block" }}>
        {isUploadingRteFile ? (
          <Loading />
        ) : (
          <>
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={handleInsertImage}
              value={fileValue}
            />
            <svg
              style={{ position: "absolute", top: "50%", transform: "translateY(-65%)" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 1000"
              data-icon="picture"
              width="100"
              height="100"
              aria-hidden="true"
            >
              <path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 632H136v-39.9l138.5-164.3 150.1 178L658.1 489 888 761.6V792zm0-129.8L664.2 396.8c-3.2-3.8-9-3.8-12.2 0L424.6 666.4l-144-170.7c-3.2-3.8-9-3.8-12.2 0L136 652.7V232h752v430.2zM304 456a88 88 0 1 0 0-176 88 88 0 0 0 0 176zm0-116c15.5 0 28 12.5 28 28s-12.5 28-28 28-28-12.5-28-28 12.5-28 28-28z" />
            </svg>
          </>
        )}
      </label>
    </div>
  )
}

ImageUploadButton.propTypes = {
  uploadFile: PropTypes.func,
  getDocumentBySlug: PropTypes.func,
  theme: PropTypes.object,
}

const mapStateToProps = bindSelectors({
  getDocumentBySlug: selectors.documentsSelectors.getDocumentBySlug,
  isUploadingRteFile: selectors.documentsSelectors.isUploadingRteFile,
})

const mapDispatchToProps = bindActions({
  uploadFile: actions.documentsActions.uploadRteFile,
})
connect(mapStateToProps, mapDispatchToProps)(ImageUploadButton)
