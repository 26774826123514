import { ASYNC, actionTypes} from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type StatisticsActionTypes = {
  statistics: {
    FETCH_STATISTICS: AsyncRequestStatus,
  }
}

const types = actionTypes({
  statistics: {
    FETCH_STATISTICS: ASYNC,
  }
}) as StatisticsActionTypes

export default types.statistics


export type TStatisticLink = {
  url: string
  title: string
  text: string
}

export type StatisticsState = {
  links: TStatisticLink[]
  loading: boolean
  error?: boolean,
}
