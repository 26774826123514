import React, { useEffect, useState } from "react"
import { Button, Drawer } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { Scrollbars } from "react-custom-scrollbars"
import LocaleLink from "src/components/LocaleLink"
import Logo from "src/components/Logo"
import routes from "src/routes"
import Navigation from "../Navigation"
import { Breadcrumbs } from "../Breadcrumbs"
import "./style.less"

const DrawerMenu = () => {
  const intl = useIntl()
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth)

  const history = useHistory();

  history.listen((location, action) => {
    handleClose();
  });


  const handleClose = () => {
    setOpen(false);
  }

  const handleResize = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize, { passive: true })
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <Button onClick={() => setOpen(true)} className="mobile-menu__button">
        <MenuOutlined />
        <span>
          {intl.formatMessage({ id: "route.menu.title" })}
        </span>
      </Button>
      <Drawer
        title={(
          <>
            <LocaleLink className="logo" to="/home" title={process.env.VERSION} onClick={handleClose}>
              <Logo />
            </LocaleLink>
            {/* @ts-expect-error: routes */}
            <Breadcrumbs routes={routes} />
          </>
        )}
        placement="left"
        width={width > 400 ? 400 : width}
        onClose={handleClose}
        open={open}
        className="drawer-menu"
      >
        <Scrollbars autoHide hideTracksWhenNotNeeded className="sidebar__scrollarea">
          {/* @ts-expect-error: routes config */}
          <Navigation routes={routes} />
        </Scrollbars>
      </Drawer>
    </>
  )
}

export default DrawerMenu
