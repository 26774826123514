import { createAsyncAction, createAsyncActionWithTreeContext } from "../utils"

import { api as externalApi } from "../../services/api"
import types from "./types"

const api = externalApi()

export const fetchCategories = createAsyncAction(types.FETCH_CATEGORIES, api.certifications.getCategories)

export const fetchCertificationBySlug = createAsyncActionWithTreeContext(
  types.FETCH_CERTIFICATION_BY_SLUG,
  api.certifications.getCertificationBySlug
)

export const fetchCertificationById = createAsyncActionWithTreeContext(
  types.FETCH_CERTIFICATION_BY_ID,
  api.certifications.getCertificationByid
)

export const createCategory = createAsyncActionWithTreeContext(types.CREATE_CATEGORY, api.certifications.createCategory)

export const uploadFile = createAsyncActionWithTreeContext(
  types.CERTIFICATION_UPLOAD_FILE,
  api.documents.uploadCertificationFile
)

export const createCertification = createAsyncActionWithTreeContext(
  types.CREATE_CERTIFICATION,
  api.certifications.createCertification
)

export const removeCertification = createAsyncActionWithTreeContext(
  types.REMOVE_CERTIFICATION,
  api.certifications.deleteFile
)
