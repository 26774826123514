import { createSelector } from "reselect"
import { isEmpty, uniqBy } from "lodash"
import { IOwnershipTableItem, OpfCommunityState, TOptContentOwnership } from "./types"
import { getCountries } from "../configurator/selectors"

export const getOpfContentOwnershipFilters = (state: { opfCommunity: OpfCommunityState }) => {
  return state.opfCommunity.filters
}

export const getOpfCommunityData = (state: { opfCommunity: OpfCommunityState }) => state.opfCommunity
export const getOpfCommunityLoading = (state: { opfCommunity: OpfCommunityState }) => state.opfCommunity.loading
export const getOpfCommunities = createSelector(getOpfCommunityData, data => {
  return data?.opfCommunities
})

export const getOpfContentOwnership = createSelector(getOpfCommunityData, getCountries, (data, countriesDictionary) => {
  if (!data?.opfCommunitiesContentOwnership?.functions) {
    return []
  }

  return data.opfCommunitiesContentOwnership.functions.map((item) => {
    let countryTableData: Record<string, IOwnershipTableItem> = {}
    if (item.countryFunctionOwners) {
      countryTableData = item.countryFunctionOwners.reduce((acc, curr) => {

        return {
          ...acc,
          [curr.countryCode]:
            {
              country: countriesDictionary.find((country: any) => country.code === curr.countryCode)?.name || curr.countryCode,
              countryCode: curr.countryCode,
              ownerName: curr.functionOwner.name,
              ownerEmail: curr.functionOwner.email
            }
        }
      }, {})
    }

    return {
      ...item,
      countryTableData: Object.values(countryTableData) as IOwnershipTableItem[]
    }
  })
})

export const getFilteredOpfOwnershipData = createSelector(
  getOpfContentOwnership,
  getOpfContentOwnershipFilters,
  (data, { functionValue, countryValues }) => {
    if (!data) {
      return []
    }
    const filteredData: TOptContentOwnership[] = data.filter(item => item.title === functionValue)

    if (countryValues) {
      return filteredData.map(item => {
        return {
          ...item,
          countryTableData: item?.countryTableData ? item.countryTableData.filter(val => countryValues.includes(val.countryCode)) : []
        }
      })
    }
    return filteredData
  })

export const getOpfContentOwnershipFunctions = createSelector(
  getOpfContentOwnership,
  getOpfContentOwnershipFilters,
  (data, { levelValues }) => {
    if (!data) {
      return []
    }

    const filteredData = levelValues && !isEmpty(levelValues) ? data.filter(
      ({type: types}) => {
        return levelValues.some(val => types.includes(val))
      }) : data

    return uniqBy(filteredData.map(val => {
      return {
        title: val.title,
        key: val.title,
        leaf: true,
        value:  val.title,
      }
    }), 'key')
})

export const getOpfContentOwnershipLevels = createSelector(
  getOpfContentOwnership,
  (data) => {
    if (!data) {
      return []
    }

    const typesMap = new Map()
    data.forEach(({type}) => {
      type.forEach(val => typesMap.set(val, true))
    })

    return Array.from(
      typesMap,
      ([type]) => ({
        title: type,
        key: type,
        leaf: true,
        value:  type,
      }))
  }
)

export const getOpfContentOwnershipCountries = createSelector(
  getOpfContentOwnership,
  getOpfContentOwnershipFilters,
  (data, { functionValue }) => {
    if (!data) {
      return []
    }

    const countriesMap = new Map()
    const filteredData: TOptContentOwnership | undefined = data.find(item => item.title === functionValue)

    if (filteredData?.countryTableData) {
      filteredData.countryTableData.forEach(val => countriesMap.set(val.countryCode, val.country))
    }

    return Array.from(
      countriesMap,
      ([code, name]) => ({
        title: name,
        key: code,
        leaf: true,
        value:  name,
      }))
  }
)
