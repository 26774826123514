import { ASYNC, actionTypes, SYNC } from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type HomeActionTypes = {
  home: {
    FETCH_HOMEPAGE: AsyncRequestStatus
  }
}

const types = actionTypes({
  home: {
    FETCH_HOMEPAGE: ASYNC,
  },
}) as HomeActionTypes

export default types.home
