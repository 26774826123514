import { ASYNC, SYNC, actionTypes } from "../utils"

const types = actionTypes({
  documents: {
    GET_LATEST_DRAFT: ASYNC,
    SAVE_DRAFT: ASYNC,
    PUBLISH_DOCUMENT: ASYNC,
    PRE_PUBLISH_MINOR_VERSION: ASYNC,
    PRE_PUBLISH_MAJOR_VERSION: ASYNC,
    FETCH_CATEGORIES: ASYNC,
    FETCH_DOCUMENT: ASYNC,
    FETCH_DOCUMENT_BY_SLUG: ASYNC,
    FETCH_FLAT_TREE_STRUCTURE_DOCUMENTS: ASYNC,
    UPDATE_DOCUMENT: ASYNC,
    UPDATE_DOCUMENT_DRAFT: ASYNC,
    UPDATE_DOCUMENT_CATEGORY: ASYNC,
    TRANSLATE_DOCUMENT: ASYNC,
    CREATE_DOCUMENT_TRANSLATION: ASYNC,
    // CREATE_VARIANT: ASYNC,
    SELECT_DOCUMENT: SYNC,
    CREATE_DOCUMENT_CATEGORY: ASYNC,
    CREATE_CATEGORY_VARIANT: ASYNC,
    TRANSLATE_DOCUMENT_CATEGORY: ASYNC,
    ARCHIVE_DOCUMENT_CATEGORY: ASYNC,
    ARCHIVE_DOCUMENT_ITEM: ASYNC,
    REMOVE_DOCUMENT_CATEGORY_FROM_CONFIGURATOR: ASYNC,
    CREATE_DOCUMENT_CATEGORY_VARIANT: ASYNC,
    CREATE_DOCUMENT_SUB_CATEGORY_VARIANT: ASYNC,
    CREATE_DOCUMENT_VARIANT: ASYNC,
    UPLOAD_FILE: ASYNC,
    UPLOAD_RTE_FILE: ASYNC,
    DOWNLOAD_PDF: SYNC,
    TOGGLE_EDIT_MODE: SYNC,
    TOGGLE_DOCUMENT_SYNCED: SYNC,
    MOVE_DOCUMENT: ASYNC,
    REMOVE_DOCUMENT_FROM_CONFIGURATOR: ASYNC,
    DOCUMENT_BLOCK: {
      ARCHIVE: SYNC,
      UNARCHIVE: SYNC,
    },
    CREATE_DOCUMENT: ASYNC,
    CREATE_DOCUMENT_DRAFT: ASYNC,
    CREATE_DOCUMENT_FROM_WORD: ASYNC,
    UPDATE_DOCUMENT_AS_FILE: ASYNC,
    CREATE_DOCUMENT_SUB_CATEGORY: ASYNC,
    UPDATE_DOCUMENT_SUB_CATEGORY: ASYNC,
    ARCHIVE_DOCUMENT_SUB_CATEGORY: ASYNC,
    TRANSLATE_DOCUMENT_SUB_CATEGORY: ASYNC,
    FETCH_LOCAL_AMENDMENT: ASYNC,
    CREATE_LOCAL_AMENDMENT: ASYNC,
    UPDATE_LOCAL_AMENDMENT: ASYNC,
    ARCHIVE_LOCAL_AMENDMENT: ASYNC,
    FETCH_CHANGELOG: ASYNC,
    AUTO_TRANSLATE_DOCUMENT: ASYNC,
    AUTO_TRANSLATE_DOCUMENT_DETAILS: ASYNC,
    LAST_TRANSLATE_DOCUMENT: ASYNC,
  },
})

export default types.documents
