import React, { FC, SyntheticEvent, useState } from "react"
import { Button, Modal, Radio, Space } from "antd"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { Field, InjectedFormProps, reduxForm, submit } from "redux-form"
import { ExtendedRichTextField, InputField } from "src/inputs/connected-input-fields"
import actions from "src/store/actions"
import selectors from "src/store/selectors"
import "./style.less"

interface IProps {
  onClose: () => void;
  onFinish: () => void;
}

interface IFormData {
  title: string;
  text: string;
}

type TCreateNewsFormComponent =  InjectedFormProps<IFormData>;

const UnconnectedCreateNewsForm = (props: TCreateNewsFormComponent) => {
  const intl = useIntl()
  const { handleSubmit } = props
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        component={InputField}
        placeholder={intl.formatMessage({ id: "news.formPlaceholder.title" })}
      />
      <Field
        name="text"
        className="create-news__text"
        component={ExtendedRichTextField}
        imageFeature
        htmlModeFeature
        placeholder={intl.formatMessage({ id: "news.formPlaceholder.text" })}
        />
    </form>
  )
}

const CreateNewsFormComponent = reduxForm({
  form: 'create-news-form-id'
  // @ts-expect-error: onSubmit as props
})(UnconnectedCreateNewsForm)

const CreateNewsForm: FC<IProps> = ({onClose, onFinish}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const appContext = useSelector(selectors.getApplicationContext)
  const [newsType, setNewsType] = useState<'news' | 'update'>('news');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: any) => {
    const {tree, ...appContextRest} = appContext

    const params = {
      applicationContext: {
        ...appContextRest,
      },
      "title": values.title,
      "text": values.text,
      "type": "news",
      "newsType": newsType,
      "publishedDate": new Date().toISOString(),
      "publishedBy": appContextRest.user.name
    }

    dispatch(actions.newsActions.createNews(params))
    onClose();
    onFinish();
  }

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(submit('create-news-form-id'))
  }

  return (
    <Modal
      width={750}
      open
      title={<h3 className="create-news__title">{intl.formatMessage({ id: "news.button.create" })}</h3>}
      onCancel={onClose}
      className="modal-component create-news"
      centered
      footer={[
        <Button
          key="back"
          type="primary"
          ghost
          className="create-news__button create-news__button--ghost"
          onClick={onClose}
        >
          {intl.formatMessage({ id: "common.close" })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="create-news__button create-news__button--primary"
          onClick={handleClick}
          loading={loading}
          disabled={loading}
        >
          {intl.formatMessage({ id: "news.button.create" })}
        </Button>
      ]}
    >
      <div className="create-news-inner">
        <Space>
          <Radio.Group value={newsType} onChange={(e) => setNewsType(e.target.value)}>
            <Radio.Button value="news">{intl.formatMessage({ id: "news.formTag.news" })}</Radio.Button>
            <Radio.Button value="update">{intl.formatMessage({ id: "news.formTag.update" })}</Radio.Button>
          </Radio.Group>
        </Space>
        <CreateNewsFormComponent onSubmit={handleSubmit} />
      </div>
    </Modal>
  )
}

export default CreateNewsForm
