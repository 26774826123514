import { createAsyncAction } from "../utils"
import { api as externalApi } from "../../services/api"
import types from "./types"

const api = externalApi()

export const fetchOpfCommunity = createAsyncAction(types.FETCH_OPF_COMMUNITIES, api.opfCommunity.getAllOpfCommunity)

export const changeFilterValue = (filterName: string, filterValue: string | string[] | undefined) => (dispatch: any) => {
  dispatch({
    type: types.SET_FILTER_VALUE,
    payload: {
      filterName,
      filterValue
    }
  })
}
