import { produce } from "immer"
import types, { ICertificationItem } from "./types"
import { arrayToSlugObject } from "../documents/utils"
import { mergeWith } from "lodash"

export type CertificationState = {
  currentItem: {
    id?: string;
    slug?: string;
    title?: string;
    file?: string;
  }
  categories: ICertificationItem[];
  files: ICertificationItem[];
  categoriesBySlug: Record<string, any>;
  loading: boolean
  error?: boolean
}

export const initialState: CertificationState = Object.freeze({
  currentItem: {
    id: undefined,
    slug: undefined,
    title: undefined,
    file: undefined
  },
  categories: [],
  files: [],
  loading: false,
  categoriesBySlug: {},
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_CATEGORIES.REQUEST:
      case types.FETCH_CERTIFICATION_BY_SLUG.REQUEST:
        writableState.loading = true
        writableState.error = false
        break
      case types.FETCH_CATEGORIES.SUCCESS:
      case types.FETCH_CERTIFICATION_BY_SLUG.SUCCESS:
      case types.CREATE_CERTIFICATION.SUCCESS:
      case types.REMOVE_CERTIFICATION.SUCCESS:
      case types.CREATE_CATEGORY.SUCCESS: {
        writableState.categories = action.payload.certificationsCategories || [];
        writableState.files = action.payload.certifications;
        writableState.currentItem = {
          id: action.payload.id,
          slug: action.payload.slug,
          title: action.payload.title,
          file: action.payload.file
        }

        const flattenedSlugStructure = arrayToSlugObject(action.payload.certificationsCategories || [])
        const flattenedCertificationsStructure = arrayToSlugObject(action.payload.certifications || [])
        writableState.categoriesBySlug = mergeWith(writableState.categoriesBySlug, flattenedSlugStructure, flattenedCertificationsStructure)

        writableState.loading = false
        writableState.error = false

        break
      }
      case types.FETCH_CERTIFICATION_BY_SLUG.FAILURE:
      case types.FETCH_CATEGORIES.FAILURE:
        writableState.loading = false
        writableState.error = true
        break

      default:
        return writableState
    }
    return writableState
  })
}
