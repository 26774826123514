import React, { FC, SyntheticEvent, useState } from "react"
import { Button, Modal } from "antd"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Field, InjectedFormProps, reduxForm, submit } from "redux-form"
import { InputField } from "src/inputs/connected-input-fields"
import "./style.less"
import { required } from "src/forms/validators"
import FormattedTitle from "../../components/FormattedTitle"

interface IProps {
  onClose: () => void;
  onFinish: (values: Record<string, string>) => void;
}

interface IFormData {
  title: string;
}

type TProps = InjectedFormProps<IFormData>;

const UnconnectedCreateCertificationCategory = (props: TProps) => {
  const intl = useIntl()
  const { error, handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        component={InputField}
        placeholder={intl.formatMessage({ id: "certifications.formPlaceholder.title" })}
        validate={[required]}
        label={<FormattedTitle id="common.title" />}
      />
      {error && <strong>{error}</strong>}
    </form>
  )
}

const CreateCertificationCategoryComponent = reduxForm({
  form: 'create-certification-category-id'
  // @ts-expect-error: onSubmit as props
})(UnconnectedCreateCertificationCategory)

const CreateCertificationCategory: FC<IProps> = ({onClose, onFinish}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: Record<string, string>) => {
    onClose();
    onFinish(values);
  }

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(submit('create-certification-category-id'))
  }

  return (
    <Modal
      width={750}
      open
      title={<h3 className="create-news__title">{intl.formatMessage({ id: "certifications.menu.addCategory" })}</h3>}
      onCancel={onClose}
      className="modal-component create-news"
      centered
      footer={[
        <Button
          key="back"
          type="primary"
          ghost
          className="create-news__button create-news__button--ghost"
          onClick={onClose}
        >
          {intl.formatMessage({ id: "common.close" })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="create-news__button create-news__button--primary"
          onClick={handleClick}
          loading={loading}
          disabled={loading}
        >
          {intl.formatMessage({ id: "certifications.button.create" })}
        </Button>
      ]}
    >
      <div className="create-news-inner">
        <CreateCertificationCategoryComponent onSubmit={handleSubmit} />
      </div>
    </Modal>
  )
}

export default CreateCertificationCategory
