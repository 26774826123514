import createAsyncActionWithContext from "./createAsyncActionWithContext"
import { getTreeFromSlug } from "src/store/utils/routeUtils"
import { trimSlug } from "src/components/helpers"

// composed async action creator function that assigns useprofile to requests
export default function createAsyncActionWithTreeContext(type, asyncFunction) {
  return (payload) => (dispatch, getState) => {
    const slug = trimSlug(window.location.pathname)

    const mergeContext = (context) => ({
      ...context,
      language: payload?.language || context.language,
      tree: getTreeFromSlug(slug),
    })
    return createAsyncActionWithContext(type, asyncFunction, null, mergeContext)(payload)(
      dispatch,
      getState
    )
  }
}
