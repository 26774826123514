import { HourglassOutlined } from "@ant-design/icons"
import { Button, notification } from "antd"
import { useIntl } from "react-intl"
import React, { useEffect } from "react"
import { isEmpty, isNil } from "lodash"
import { useSelector } from "react-redux"
import { ROLES } from "src/globals"

import useActions from "src/hooks/useActions"

import actions from "src/store/actions"
import { sanitizeHTML } from "src/components/helpers"
import selectors from "src/store/selectors"

const unauthenticatedValue = "userIsNoLongerAuthenticated"

export default function useNotifications() {
  const intl = useIntl()
  const user = useSelector(selectors.userSelectors.getUser)
  const messages = useSelector(selectors.uiSelectors.getMessages)
  const initialized = useSelector(selectors.uiSelectors.getInitializationFinished)
  const loading = useSelector(selectors.uiSelectors.getApplicationLoadingState)
  const anonymousMessageClosed = useSelector(selectors.uiSelectors.getAnonymousMessageClosed)

  const [clearMessages, closeAnonymousMessage, checkAuthenticationStatus, trackException] =
    useActions([
      actions.uiActions.clearMessages,
      actions.uiActions.closeAnonymousMessage,
      actions.userActions.checkAuthenticationStatus,
      actions.uiActions.trackException,
    ])

  // handling of alerts and error messages
  useEffect(() => {
    if (loading || isEmpty(messages)) return

    window.requestIdleCallback(() => {
      messages
        .filter((message) => message !== undefined)
        .forEach(({ message, title, type, status, timestamp, action }) => {
          const errorMessage = action?.payload?.response?.data?.description
          window.requestAnimationFrame(() => {
            notification[type]({
              duration: 20,
              key: status || timestamp,
              message: intl.formatMessage({ id: `error.${status}.title`, defaultMessage: title }),
              description:
                errorMessage ||
                message ||
                intl.formatMessage({
                  id: `error.${status}.description`,
                }),
            })
            trackException({ message: errorMessage || message })
          })
        })
    })

    clearMessages()
  }, [intl, loading, messages, trackException, clearMessages])

  // Session timeout notification
  useEffect(() => {
    if (initialized) {
      if (user.authenticated || anonymousMessageClosed) {
        notification.close(unauthenticatedValue)
      } else {
        const timedOutDescription = intl.formatMessage({
          id: "message.sessionTimedOut.description",
        })
        const openAuthenticationWindow = () => {
          const height = 700
          const width = 1000
          const y = window.top.outerHeight / 2 + window.top.screenY - height / 2
          const x = window.top.outerWidth / 2 + window.top.screenX - width / 2
          const settings = `height=${height},width=${width},left=${x},top=${y},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no`
          const authenticationWindow = window.open(
            window.location.origin + "/authenticated",
            "login",
            settings
          )
          authenticationWindow.focus()
          const timer = window.setInterval(() => {
            if (authenticationWindow.closed) {
              closeAnonymousMessage()
              window.requestIdleCallback(checkAuthenticationStatus)
              clearInterval(timer)
            }
          }, 1000)
        }

        const authenticateButton = (
          <Button
            type="primary"
            size="large"
            className="authenticate-button"
            onClick={openAuthenticationWindow}
          >
            {intl.formatMessage({ id: "common.authenticate" })}
          </Button>
        )

        window.requestAnimationFrame(() => {
          notification.info({
            message: intl.formatMessage({ id: "message.sessionTimedOut.title" }),
            description: <p dangerouslySetInnerHTML={sanitizeHTML(timedOutDescription)} />,
            duration: 0,
            onClose: closeAnonymousMessage,
            icon: <HourglassOutlined />,
            btn: authenticateButton,
            key: unauthenticatedValue,
          })
        })
      }
    }
  }, [
    intl,
    user.authenticated,
    anonymousMessageClosed,
    initialized,
    checkAuthenticationStatus,
    closeAnonymousMessage,
  ])

  // Anonymous user notification
  useEffect(() => {
    if (!initialized) return

    const anonymousDescription = intl.formatMessage({ id: "message.anonymous.description" })
    if (isNil(user.currentRole)) return null
    if (user.currentRole.name === ROLES.ANONYMOUS && !anonymousMessageClosed) {
      window.requestAnimationFrame(() =>
        notification.info({
          message: intl.formatMessage({ id: "message.anonymous.title" }),
          description: <p dangerouslySetInnerHTML={sanitizeHTML(anonymousDescription)} />,
          duration: 0,
          key: "anonNotification",
          onClose: closeAnonymousMessage,
        })
      )
    }
  }, [intl, anonymousMessageClosed, initialized, user, closeAnonymousMessage])

  // // Unsupported browser notification
  // useEffect(() => {
  //   if (!initialized) return
  //   if (IS_SUPPORTED_BROWSER) return

  //   window.requestAnimationFrame(() =>
  //     notification.warn({
  //       message: intl.formatMessage({ id: "message.unsupportedBrowser.title" }),
  //       description: intl.formatMessage({ id: "message.unsupportedBrowser.description" }),
  //       key: "unsupportedBrowserNotification",
  //       duration: 0,
  //     })
  //   )
  // }, [initialized, intl])

  // // Unsupported browser alert when printing
  // useEffect(() => {
  //   const printListener = (_event) => {
  //     if (IS_SUPPORTED_BROWSER) return

  //     alert(intl.formatMessage({ id: "message.unsupportedBrowser.printAlert" }))
  //   }
  //   window.addEventListener("beforeprint", printListener)

  //   return () => window.removeEventListener("beforeprint", printListener)
  // }, [intl])
}
