import { isArray, isEmpty, pick, uniq, uniqBy } from "lodash"

import { getItemById } from "./selectors"

/**
 * @typedef {String} OPFDocumentItemTypes
 * @value 'policy'
 * @value 'standard'
 * @value 'chapter'
 * @value 'section'
 */

/**
 * @param {OPFDocumentItemTypes} type
 */
export const getChildPropertyForItemType = (type) => {
  switch (type) {
    case "policy":
    case "standard":
      return "chapters"
    case "chapter":
      return "sections"
    case "section":
      return "subsections"
    default: //
  }
  return null
}

export const normalizeToSlugObject = (item) => (item ? { [item.slug]: item } : null)
export const toSlugObject = (accum, value) => ({ ...accum, ...normalizeToSlugObject(value) })
export const arrayToSlugObject = (opfDocumentItems) => opfDocumentItems.reduce(toSlugObject, {})
export const removeKey = (key, { [key]: _, ...rest }) => rest
export const byOrder = (a, b) => a.order - b.order
export const getOpfItemIdentifiers = (opfDocument) =>
  Object.values(pick(opfDocument, ["slug", "opfId", "id"]))
const getParentItem = (item, documents) => getItemById({ documents })(item.parentId)

export const updateDocumentItem = (opfDocument, documents) => {
  // FIXME: simplify this - and maybe move the reducer instead of being a utils function
  // documents.bySlug[opfDocument] = { ...documents.bySlug[opfDocument], ...opfDocument }
  // return documents

  documents.itemBeingUpdated = null
  const existingItem = documents.bySlug[opfDocument.slug]

  // When creating variants and translations an object with an empty set of children get returned
  // To prevent children to dissappearing when that happens, we reuse the old set of children
  if (
    existingItem &&
    !isEmpty(existingItem.opfDocumentChildren) &&
    isEmpty(opfDocument.opfDocumentChildren)
  ) {
    documents.bySlug[opfDocument.slug] = {
      ...opfDocument,
      opfDocumentChildren: existingItem.opfDocumentChildren,
    }
    return documents
  }

  const parentItem = Object.values(documents.bySlug).find(
    ({ opfId }) => opfId === opfDocument.parentId
  )
  const siblingItems = parentItem?.opfDocumentChildren
  if (siblingItems) {
    upsertChild(siblingItems, opfDocument)
  }

  // FIXME: Is the empty object / array necessary?
  documents.bySlug[opfDocument.slug] =
    !isEmpty(documents.bySlug) && documents.bySlug !== null ? opfDocument : {}
  documents.allItems = !isEmpty(documents.allItems)
    ? uniq([...documents.allItems, opfDocument.slug])
    : []

  return documents
}

// TODO: remove child opfDocumentChildren property to prevent duplicate data in state
/** recursively go through opf structure and generate array */
const getOpfChildrenRecursively = (item) => {
  if (!isEmpty(item.opfDocumentChildren)) {
    return [
      { ...item, hasChildren: true },
      ...item.opfDocumentChildren.flatMap(getOpfChildrenRecursively),
    ]
  }
  return [item]
}

/** flatten a collection of opf items recursively */
export const flattenOpfDocumentItems = (items) => {
  // flattens collection of opf items
  items = isArray(items) ? items : [items]
  return items.flatMap(getOpfChildrenRecursively)
}

export const mergeDocuments = (objValue, srcValue) => {
  if (objValue) {
    if (srcValue.loaded) {
      return srcValue
    }
    if (
      isEmpty(srcValue.availableActions) ||
      objValue.availableActions !== null ||
      objValue.version > srcValue.version
    ) {
      return { ...objValue, _parentItemId: srcValue._parentItemId || objValue._parentItemId }
    }
    return objValue
  }
  return srcValue
}

export const flattenDocumentHierarchy = (accum, item) => {
  if ("chapters" in item) {
    const chapters = item.chapters || []
    const updatedChildren = chapters
      .map((chapter) => ({ ...chapter, _parentItemId: item.opfId }))
      .reduce(flattenDocumentHierarchy, accum)

    return {
      ...accum,
      ...updatedChildren,
      documents: uniqBy({ ...accum.documents, [item.opfId]: { ...item, chapters: null } }, "id"),
      chapters: uniqBy({ ...accum.chapters, ...updatedChildren.chapters }, "id"),
    }
  }
  if ("sections" in item) {
    const sections = item.sections || []
    const updatedChildren = sections
      .map((section) => ({ ...section, _parentItemId: item.id }))
      .reduce(flattenDocumentHierarchy, accum)

    return {
      ...accum,
      ...updatedChildren,
      chapters: uniq({ ...accum.chapters, [item.id]: { ...item, sections: null } }, "id"),
      sections: uniqBy({ ...accum.sections, ...updatedChildren.sections }, "id"),
    }
  }
  if ("subsections" in item) {
    const subsections = item.subsections || []
    const updatedChildren = uniqBy(
      subsections
        .map((subsection) => ({ ...subsection, _parentItemId: item.id }))
        .reduce(flattenDocumentHierarchy, accum),
      "id"
    )

    return {
      ...accum,
      ...updatedChildren,
      sections: uniqBy({ ...accum.sections, [item.id]: { ...item, subsections: null } }, "id"),
    }
  }
  if (item.type === "subsection") {
    return {
      ...accum,
      subsections: uniqBy({ ...accum.subsections, [item.id]: item }, "id"),
    }
  }
  return accum
}

export const upsertChild = (children, newChild) => {
  const childIndex = children.findIndex(({ opfId }) => opfId === newChild.opfId)
  if (childIndex >= 0) {
    children.splice(childIndex, 1, newChild)
  } else {
    children.push(newChild)
  }
}

export const removeDocumentItemAndUpdateParentChildren = (item, documents) => {
  const itemIdentifiers = getOpfItemIdentifiers(item) // get id and slug for opf item to remove
  const allItemsWithoutRemoved = documents.allItems.filter(
    (identifier) => !itemIdentifiers.includes(identifier)
  ) // removal of archived item
  const parentItem = getParentItem(item, documents)
  documents.allItems = allItemsWithoutRemoved
  documents.bySlug = removeKey(item.slug, documents.bySlug)

  // finish updating if there is no parent item
  if (parentItem === undefined) return documents
  // update item parent's child collection to reflect changes made to it's child item
  const children = parentItem.opfDocumentChildren || []
  parentItem.opfDocumentChildren = children
    .filter((child) => child && child.slug !== item.slug)
    .sort(byOrder)

  // update parent item with new changes
  return updateDocumentItem(parentItem, documents)
}
