import {
  arrayToSlugObject,
  flattenDocumentHierarchy,
  flattenOpfDocumentItems,
  mergeDocuments,
  removeDocumentItemAndUpdateParentChildren,
  updateDocumentItem,
} from "./utils"
import { mergeWith, uniq } from "lodash"

import configuratorTypes from "../configurator/types"
import localeTypes from "../locale/types"
import { produce } from "immer"
import types from "./types"
import uiTypes from "../ui/types"
import userTypes from "../user/types"

const emptyHierarchy = Object.freeze({
  chapters: Object.freeze({}),
  sections: Object.freeze({}),
  subsections: Object.freeze({}),
})

// FIXME: figure out if the 'documents' within the documents store can be avoided (used in FETCH_CATEGORIES.SUCCESS etc)

export const initialState = Object.freeze({
  allItems: [], // FIXME: Remove allItems, potentially use Object.keys(bySlug) to get all slugs
  bySlug: {},
  availableCategoryActions: null,
  documentEditMode: false,
  documentSynced: false,
  archivedDocumentBlocks: [],
  newestDocument: null,
  selectedDocument: null,
  fetchingCategories: false,
  isUploadingRteFile: false,
  translatedDocument: null,
  ...emptyHierarchy,
})

export const mergeVersions = (originalVersion, updatedVersion) =>
  originalVersion
    ? {
        ...originalVersion,
        ...updatedVersion,
        type: originalVersion.type,
      }
    : updatedVersion

export const mergeActions = (document, availableActions) =>
  availableActions ? { ...document, availableActions } : document

export const mergeVersionsWithActions = (originalVersion, updatedVersion, availableActions) => {
  const mergedDocument = mergeVersions(originalVersion, updatedVersion)
  return mergeActions(mergedDocument, availableActions)
}

export default function reducer(state = initialState, action) {
  return produce(state, (documents) => {
    switch (action.type) {
      case uiTypes.FORM_MODAL.CLOSE:
        documents.selectedDocument = null
        break

      // case types.UPDATE_DOCUMENT_CATEGORY.SUCCESS:
      case types.CREATE_CATEGORY_VARIANT.SUCCESS:
      case types.CREATE_DOCUMENT_VARIANT.SUCCESS: {
        const originalDocument = action.meta.request.opfDocument
        const { opfDocument, availableActions } = action.payload
        const mergedDocument = mergeVersionsWithActions(
          originalDocument,
          opfDocument,
          availableActions
        )
        return updateDocumentItem(mergedDocument, documents)
      }

      case types.TRANSLATE_DOCUMENT_CATEGORY.SUCCESS:
      case types.UPDATE_DOCUMENT_CATEGORY.SUCCESS: {
        const { opfDocument } = action.meta.request
        const { documentCategoryVersion, availableActions } = action.payload
        const mergedDocument = mergeVersionsWithActions(
          opfDocument,
          documentCategoryVersion,
          availableActions
        )
        return updateDocumentItem(mergedDocument, documents)
      }

      case types.CREATE_DOCUMENT.REQUEST:
      case types.CREATE_DOCUMENT_SUB_CATEGORY.REQUEST:
        documents.newestDocument = null
        break

      case types.UPDATE_DOCUMENT_AS_FILE.SUCCESS:
      case types.UPDATE_DOCUMENT.SUCCESS:
      case types.CREATE_DOCUMENT.SUCCESS:
      case types.TRANSLATE_DOCUMENT.SUCCESS: {
        const { document, documentVersion, availableActions } = action.payload
        const mergedDocument = mergeVersionsWithActions(document, documentVersion, availableActions)
        return updateDocumentItem(mergedDocument, documents)
      }

      case types.SAVE_DRAFT.SUCCESS: {
        const { opfDocument, availableActions } = action.payload
        const mergedDocument = mergeActions(opfDocument, availableActions)
        return updateDocumentItem(mergedDocument, documents)
      }

      case types.CREATE_DOCUMENT_DRAFT.SUCCESS:
      case types.CREATE_DOCUMENT_FROM_WORD.SUCCESS:
      case types.PUBLISH_DOCUMENT.SUCCESS:
        return updateDocumentItem(action.payload.opfDocument, documents)

      case types.CREATE_DOCUMENT_SUB_CATEGORY_VARIANT.SUCCESS:
      case types.CREATE_DOCUMENT_SUB_CATEGORY.SUCCESS: {
        const { documentSubCategory, documentSubCategoryVersion, availableActions } = action.payload
        const mergedDocument = mergeVersionsWithActions(
          documentSubCategory,
          documentSubCategoryVersion,
          availableActions
        )
        return updateDocumentItem(mergedDocument, documents)
      }

      case types.UPLOAD_RTE_FILE.REQUEST:
        documents.isUploadingRteFile = true
        break
      case types.UPLOAD_RTE_FILE.FAILURE:
      case types.UPLOAD_RTE_FILE.SUCCESS:
        documents.isUploadingRteFile = false
        break

      case types.TRANSLATE_DOCUMENT_SUB_CATEGORY.SUCCESS:
      case types.UPDATE_DOCUMENT_SUB_CATEGORY.SUCCESS: {
        const { opfDocument } = action.meta.request
        const { documentSubCategoryVersion, availableActions } = action.payload
        const mergedDocument = mergeVersionsWithActions(
          opfDocument,
          documentSubCategoryVersion,
          availableActions
        )
        return updateDocumentItem(mergedDocument, documents)
      }

      case types.ARCHIVE_DOCUMENT_SUB_CATEGORY.SUCCESS: {
        const { opfDocument } = action.meta.request
        return removeDocumentItemAndUpdateParentChildren(opfDocument, documents)
      }

      case userTypes.FETCH_CONFIGURATOR.SUCCESS:
      case configuratorTypes.SAVE_DOCUMENTS_FOR_CONFIGURATOR.SUCCESS:
      case localeTypes.SET_LANGUAGE: // ? clear items state when configurator or language is changed
        return clearDocuments(documents)

      case types.FETCH_CATEGORIES.REQUEST:
        documents.fetchingCategories = true
        break

      case types.FETCH_CATEGORIES.FAILURE:
        documents.fetchingCategories = false
        break

      case types.FETCH_CATEGORIES.SUCCESS: {
        // FIXME: Figure out what this does, and if it is important
        const flattenedHierarchy = action.payload.opfDocuments.reduce(
          flattenDocumentHierarchy,
          emptyHierarchy
        )
        const opfDocuments = action.payload.opfDocuments
        const bySlug = { ...state.bySlug }

        for (let opfDocument of opfDocuments) {
          const mergedOpfDocument = { ...bySlug[opfDocument.slug], ...opfDocument }
          bySlug[opfDocument.slug] = mergedOpfDocument
        }

        const mergedState = {
          ...state,
          documents: mergeWith(
            { ...state.documents },
            flattenedHierarchy.documents,
            mergeDocuments
          ),
          bySlug,
          allItems: uniq([...state.allItems, ...Object.keys(bySlug)]),
          availableCategoryActions: action.payload.availableActions,
          fetchingCategories: false,
        }
        return mergedState
      }

      case types.FETCH_DOCUMENT.SUCCESS:
      case types.FETCH_DOCUMENT_BY_SLUG.SUCCESS:
      case types.GET_LATEST_DRAFT.SUCCESS:
        return addItems(action, documents)

      case types.FETCH_FLAT_TREE_STRUCTURE_DOCUMENTS.SUCCESS: {
        const tree = action.meta.request.tree
        const nodes = action.payload.nodes
        const nodesBySlug = nodes.reduce((accumulated, currentNode) => {
          return { ...accumulated, [currentNode.slug]: { ...currentNode, tree } }
        }, {})

        mergeWith(documents.bySlug, nodesBySlug)
        documents.allItems = uniq([...documents.allItems, ...Object.keys(nodesBySlug)])
        break
      }

      case types.CREATE_DOCUMENT_CATEGORY_VARIANT.SUCCESS:
      case types.CREATE_DOCUMENT_CATEGORY.SUCCESS: {
        const { documentCategory, documentCategoryVersion, availableActions } = action.payload
        const mergedCategory = mergeVersionsWithActions(
          documentCategory,
          documentCategoryVersion,
          availableActions
        )
        return {
          ...documents,
          allItems: [...documents.allItems, mergedCategory.slug],
          bySlug: {
            ...documents.bySlug,
            [mergedCategory.slug]: { ...mergedCategory, parentId: null }, // Categories have a null parentId
          },
        }
      }

      case types.MOVE_DOCUMENT.SUCCESS:
        return moveDocument(action, documents)
      case types.REMOVE_DOCUMENT_CATEGORY_FROM_CONFIGURATOR.SUCCESS: {
        const { opfDocumentCategory } = action.meta.request
        return removeDocumentItemAndUpdateParentChildren(opfDocumentCategory, documents)
      }

      case types.ARCHIVE_DOCUMENT_ITEM.SUCCESS:
      case types.ARCHIVE_DOCUMENT_CATEGORY.SUCCESS: {
        const { opfDocument } = action.meta.request
        return removeDocumentItemAndUpdateParentChildren(opfDocument, documents)
      }

      case types.REMOVE_DOCUMENT_FROM_CONFIGURATOR.SUCCESS: {
        const { opfDocument } = action.meta.request
        return removeDocumentItemAndUpdateParentChildren(opfDocument, documents)
      }

      case types.ARCHIVE_DOCUMENT_ITEM.FAILURE:
        {
          const documentAttemptedToArchive = action.meta.request.opfDocument
          documents.bySlug[documentAttemptedToArchive.opfId] = documentAttemptedToArchive
        }
        break

      case types.DOCUMENT_BLOCK.ARCHIVE:
        documents.archivedDocumentBlocks.push(action.payload)
        break

      case types.DOCUMENT_BLOCK.UNARCHIVE:
        documents.archivedDocumentBlocks = documents.archivedDocumentBlocks.filter(
          (blockId) => blockId !== action.payload
        )
        break

      case types.SELECT_DOCUMENT: // used to set a target for form modals etc.
        documents.selectedDocument = action.payload.opfDocument
        break

      case types.TOGGLE_EDIT_MODE:
        documents.documentEditMode = !documents.documentEditMode
        if (action.payload) {
          documents.documentEditMode = action.payload
        }
        if (documents.documentEditMode === false) {
          documents.archivedDocumentBlocks = []
        }
        break
      case types.TOGGLE_DOCUMENT_SYNCED:
        documents.documentSynced = action.payload
        break

      case types.LAST_TRANSLATE_DOCUMENT.SUCCESS:
      case types.AUTO_TRANSLATE_DOCUMENT.SUCCESS:
        documents.translatedDocument = action.payload
        break
      default: //
    }
    return documents
  })
}

function clearDocuments(documents) {
  return {
    ...documents,
    ...initialState,
    ...emptyHierarchy,
  }
}

function addItems(action, documents) {
  const { opfDocument } = action.payload // received results
  if (!opfDocument) return
  opfDocument.loaded = true
  const flattenedItems = flattenOpfDocumentItems(opfDocument)
  const flattenedSlugStructure = arrayToSlugObject(flattenedItems)
  const newItemSlugs = flattenedItems.map((item) => item.slug)
  documents.allItems = uniq([...documents.allItems, ...newItemSlugs]) // update list of item slugs
  documents.bySlug = mergeWith(documents.bySlug, flattenedSlugStructure, mergeDocuments) // merge updated items with existing collection
  return documents
}

function moveDocument(action, documents) {
  const originalDocument = action.meta.request.opfDocument
  const newDocument = action.payload.opfDocument

  documents = removeDocumentItemAndUpdateParentChildren(originalDocument, documents)
  documents.allItems = uniq([...documents.allItems, newDocument.slug])
  documents.bySlug = { ...documents.bySlug, [newDocument.slug]: newDocument }
  return documents
}
