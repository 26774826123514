import { Input, Select } from "antd"
import React, { Component } from "react"

import PropTypes from "prop-types"
import { injectIntl } from "react-intl"

const Option = Select.Option

export class CustomLinkInput extends Component {
  constructor(props) {
    const { value } = props
    const protocol = value.startsWith("http")
      ? value.split("://")[0] + "://"
      : value.startsWith("tel:")
      ? "tel:"
      : "mailto:"
    const path = value.startsWith("http")
      ? value.split("://")[1]
      : value.startsWith("tel:")
      ? value.split("tel:")[1]
      : value

    super(props)

    this.state = {
      protocol: value ? protocol : "http://",
      path: value ? path : "",
    }
  }

  handleChangeProtocol = (value) => {
    this.setState({ protocol: value })
  }

  handleChangeInputValue = (e) => {
    this.setState({ path: e.target.value })
  }

  getCurrentValue = () => {
    const { protocol, path } = this.state
    const url = path.startsWith("http") ? path : protocol + path
    const newUrlValue = protocol !== "mailto:" ? url : path
    return newUrlValue
  }

  onChange = (event) => {
    const { onChange } = this.props
    const { path } = this.state

    let tempState = null

    if (path.startsWith("http://")) {
      tempState = {
        protocol: path.split("://")[0] + "://",
        path: path.split("://")[1],
      }
    } else if (path.startsWith("www")) {
      tempState = {
        protocol: "http://",
        path: path,
      }
    } else if (path.startsWith("mailto:") || path.includes("@")) {
      tempState = {
        protocol: "mailto:",
        path: path.startsWith("mailto:") ? path.split("mailto:")[1] : path,
      }
    } else if (path.startsWith("tel:")) {
      tempState = {
        protocol: "tel:",
        path: path.split("tel:")[1],
      }
    }

    tempState
      ? this.setState({ ...tempState }, () => onChange(this.getCurrentValue()))
      : onChange(this.getCurrentValue())
  }

  handleOnBlur = (event) => {
    const { onBlur } = this.props
    const { path } = this.state
    let tempState = null

    if (path.startsWith("http://")) {
      tempState = {
        protocol: path.split("://")[0] + "://",
        path: path.split("://")[1],
      }
    } else if (path.startsWith("www")) {
      tempState = {
        protocol: "http://",
        path: path,
      }
    } else if (path.startsWith("mailto:") || path.includes("@")) {
      tempState = {
        protocol: "mailto:",
        path: path.startsWith("mailto:") ? path.split("mailto:")[1] : path,
      }
    } else if (path.startsWith("tel:")) {
      tempState = {
        protocol: "tel:",
        path: path.split("tel:")[1],
      }
    }

    tempState
      ? this.setState({ ...tempState }, () => onBlur(this.getCurrentValue()))
      : onBlur(this.getCurrentValue())
  }

  render() {
    const { intl, value, ...rest } = this.props
    // const protocol = value.startsWith('http') ? value.split('://')[0] + '://' : value.startsWith('tel:') ? 'tel:' : 'mailto:'
    // const path = value.startsWith('http') ? value.split('://')[1] : value.startsWith('tel:') ? value.split('tel:')[1] : value
    const selectBefore = (
      <Select
        // value={protocol}
        onBlur={this.handleOnBlur}
        defaultValue="http://"
        value={this.state.protocol}
        onChange={this.handleChangeProtocol}
      >
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
        <Option value="mailto:">mailto:</Option>
        <Option value="tel:">tel:</Option>
      </Select>
    )
    return (
      <Input
        {...rest}
        value={this.state.path}
        addonBefore={selectBefore}
        onBlur={this.handleOnBlur}
        onChange={this.handleChangeInputValue}
        placeholder={intl.formatMessage({ id: "input.addLink.linkField.placeholder" })}
      />
    )
  }
}

CustomLinkInput.propTypes = {
  value: PropTypes.any,
}
export default injectIntl(CustomLinkInput)
