import { Suspense, lazy } from "react"
import { Alert } from "antd"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { ACTIONS, OPF_DOCUMENT_TYPES, OPF_ITEM_TYPES } from "../../globals"
import Loading from "src/components/Loading"
import { OpfItemShape } from "src/components/opf-prop-types"
import selectors from "src/store/selectors"

const ProcessForm = lazy(() => import("../ProcessForm"))
const ProcedureForm = lazy(() => import("../ProcedureForm"))
const ProcedureToolsForm = lazy(() => import("../ProcedureToolsForm"))
const DocumentCategoryForm = lazy(() => import("../DocumentCategoryForm"))
const DocumentMetaForm = lazy(() => import("../DocumentMetaForm"))
const SetOrderForm = lazy(() => import("../SetOrderForm"))
const NotificationForm = lazy(() => import("../NotificationForm"))
const MinorMajorForm = lazy(() => import("../MinorMajorForm"))
const CertificationMetaForm = lazy(() => import("../CertificationMetaForm"))

const {
  PROCESS,
  PROCESS_CATEGORY,
  PROCEDURE_CATEGORY,
  PROCESS_ELEMENT,
  PROCEDURE_ELEMENT,
  PROCEDURE,
} = OPF_ITEM_TYPES

const {
  POLICIES_STANDARDS_CATEGORY,
  POLICIES_STANDARDS_SUBCATEGORY,
  DOCUMENT_CATEGORY,
  POLICY,
  STANDARD,
  DOCUMENT,
  DOCUMENT_VARIANT,
  CERTIFICATION,
  CERTIFICATION_CATEGORY,
  CERTIFICATION_SUBCATEGORY
} = OPF_DOCUMENT_TYPES

const { ADD_PROCEDURE, UPDATE_ORDER, SET_NOTIFICATION, SET_PUBLISH_VERSION } = ACTIONS

export const getFormForType = (type) => {
  switch (type) {
    case PROCESS:
    case PROCESS_CATEGORY:
    case PROCEDURE_CATEGORY:
    case PROCESS_ELEMENT:
      return ProcessForm
    case PROCEDURE:
      return ProcedureForm
    case PROCEDURE_ELEMENT:
      return ProcedureToolsForm
    case POLICIES_STANDARDS_CATEGORY:
    case DOCUMENT_CATEGORY:
    case POLICIES_STANDARDS_SUBCATEGORY:
      return DocumentCategoryForm
    case POLICY:
    case STANDARD:
    case DOCUMENT:
    case DOCUMENT_VARIANT:
      return DocumentMetaForm
    case CERTIFICATION:
    case CERTIFICATION_CATEGORY:
    case CERTIFICATION_SUBCATEGORY:
      return CertificationMetaForm;
    default:
      return () => <Alert type="error" message={`Error: Form not found for type ${type}`} banner />
  }
}

const getFormByMode = (mode) => {
  switch (mode) {
    case ADD_PROCEDURE:
      return ProcedureForm
    case UPDATE_ORDER:
      return SetOrderForm
    case SET_NOTIFICATION:
      return NotificationForm
    case SET_PUBLISH_VERSION:
      return MinorMajorForm
    default:
      return () => <Alert type="error" message="Error" banner />
  }
}

export const FormHandler = (props) => {
  const callback = useSelector(selectors.uiSelectors.getFormModalCallback)
  const method = useSelector(selectors.uiSelectors.getFormModalMethod)
  const Form =
    props.mode === UPDATE_ORDER ||
    props.mode === SET_NOTIFICATION ||
    props.mode === SET_PUBLISH_VERSION
      ? getFormByMode(props.mode)
      : getFormForType(props.item.type)

  console.log('FormHandler', props.mode, props.item.type,     props.mode === UPDATE_ORDER ||
    props.mode === SET_NOTIFICATION ||
    props.mode === SET_PUBLISH_VERSION)
  if (props.item) {
    return (
      <Suspense fallback={<Loading />}>
        <Form {...props} callback={callback} method={method} />
      </Suspense>
    )
  } else {
    return <Loading />
  }
}

FormHandler.propTypes = {
  item: PropTypes.oneOfType([OpfItemShape]),
  mode: PropTypes.string,
}
export default FormHandler
