import { createAction } from "redux-actions"

import { ANALYTICS } from "src/globals"
import { createAsyncActionWithContext, createAsyncActionWithTreeContext } from "src/store/utils"
import types from "src/store/documents/types"
import { featureIsActive } from "src/services/featureFlags"
import { api as externalApi } from "../../services/api"

const api = externalApi()

export const fetchFlatTreeDocuments = createAsyncActionWithTreeContext(
  types.FETCH_FLAT_TREE_STRUCTURE_DOCUMENTS,
  api.documents.fetchFlatTreeStructureDocuments
)
export const fetchDocument = createAsyncActionWithTreeContext(
  types.FETCH_DOCUMENT,
  api.documents.getDocument
)
export const fetchDocumentBySlug = createAsyncActionWithTreeContext(
  types.FETCH_DOCUMENT_BY_SLUG,
  api.documents.getDocumentBySlug
)
export const getLatestDraft = createAsyncActionWithTreeContext(
  types.GET_LATEST_DRAFT,
  api.documents.getLatestDraft
)
export const fetchChangelog = createAsyncActionWithTreeContext(
  types.FETCH_CHANGELOG,
  api.documents.fetchDocumentChangelog
)
export const removeDocumentCategoryFromConfigurator = createAsyncActionWithTreeContext(
  types.REMOVE_DOCUMENT_CATEGORY_FROM_CONFIGURATOR,
  api.documents.removeDocumentCategoryFromConfigurator
)
// export const createDocumentVariant = featureIsActive("document_draft")
//   ? createAsyncActionWithTreeContext(types.CREATE_VARIANT, api.documents.createVariant)
//   : createAsyncActionWithTreeContext(
//       types.CREATE_DOCUMENT_VARIANT,
//       api.documents.createDocumentVariant
//     )
export const removeDocumentFromConfigurator = createAsyncActionWithTreeContext(
  types.REMOVE_DOCUMENT_FROM_CONFIGURATOR,
  api.documents.removeDocumentFromConfigurator
)
export const uploadDocumentFile = createAsyncActionWithTreeContext(
  types.UPLOAD_FILE,
  api.documents.uploadDocumentFile
)
export const uploadRteFile = createAsyncActionWithTreeContext(
  types.UPLOAD_RTE_FILE,
  api.documents.uploadRteFile
)
export const toggleDocumentEditMode = createAction(types.TOGGLE_EDIT_MODE)
export const toggleDocumentSynced = createAction(types.TOGGLE_DOCUMENT_SYNCED)
export const archiveDocumentBlock = createAction(types.DOCUMENT_BLOCK.ARCHIVE)
export const unarchiveDocumentBlock = createAction(types.DOCUMENT_BLOCK.UNARCHIVE)
export const downloadPDF = createAction(types.DOWNLOAD_PDF, null, (opfDocument) => {
  return {
    gtm: {
      actionType: ANALYTICS.POLICY_STANDARD_ACTIONS,
      item: opfDocument,
      actionTextValue: "download document pdf",
    },
  }
})

const selectDocumentAction = createAction(types.SELECT_DOCUMENT)
export const selectDocument = (payload) => (dispatch, getState) =>
  dispatch(selectDocumentAction(payload))
export const moveDocument = createAsyncActionWithContext(
  types.MOVE_DOCUMENT,
  api.documents.moveDocument
)

// ===== Document ===== //
export const createDocument = featureIsActive("document_draft")
  ? createAsyncActionWithTreeContext(types.CREATE_DOCUMENT_DRAFT, api.documents.createDocumentDraft)
  : createAsyncActionWithTreeContext(types.CREATE_DOCUMENT, api.documents.createDocument)
export const createDocumentFromWord = createAsyncActionWithTreeContext(
  types.CREATE_DOCUMENT_FROM_WORD,
  api.documents.createDocumentFromWord
)
export const translateDocument = featureIsActive("document_draft")
  ? createAsyncActionWithTreeContext(
      types.CREATE_DOCUMENT_TRANSLATION,
      api.documents.createTranslation
    )
  : createAsyncActionWithTreeContext(types.TRANSLATE_DOCUMENT, api.documents.translateDocument)
export const updateDocument = createAsyncActionWithTreeContext(
  types.UPDATE_DOCUMENT,
  api.documents.updateDocument
)
export const autoTranslatedDocument = createAsyncActionWithTreeContext(
  types.AUTO_TRANSLATE_DOCUMENT,
  api.documents.autoTranslatedDocument
)
export const autoTranslatedDocumentSubDetails = createAsyncActionWithTreeContext(
  types.AUTO_TRANSLATE_DOCUMENT_DETAILS,
  api.documents.autoTranslatedDocumentSubDetails
)
export const getLatestTranslation = createAsyncActionWithTreeContext(
  types.LAST_TRANSLATE_DOCUMENT,
  api.documents.getLatestDraft
)
export const saveDraft = createAsyncActionWithTreeContext(types.SAVE_DRAFT, api.documents.saveDraft)
export const publishDocument = createAsyncActionWithTreeContext(
  types.PUBLISH_DOCUMENT,
  api.documents.publishDocument
)
export const prePublishMinorVersion = createAsyncActionWithTreeContext(
  types.PRE_PUBLISH_MINOR_VERSION,
  api.documents.prePublishMinorVersion
)
export const prePublishMajorVersion = createAsyncActionWithTreeContext(
  types.PRE_PUBLISH_MAJOR_VERSION,
  api.documents.prePublishMajorVersion
)
export const updateRelatedItems = createAsyncActionWithTreeContext(
  types.UPDATE_DOCUMENT_DRAFT,
  api.documents.updateRelatedItems
)
export const updateLinks = createAsyncActionWithTreeContext(
  types.UPDATE_DOCUMENT_DRAFT,
  api.documents.updateLinks
)
export const updateDocumentAsFile = createAsyncActionWithTreeContext(
  types.UPDATE_DOCUMENT_DRAFT,
  api.documents.updateDocumentAsFile
)

export const archiveDocument = createAsyncActionWithTreeContext(
  types.ARCHIVE_DOCUMENT_ITEM,
  api.documents.archiveDocument
)

// ===== Document category ===== //
export const fetchDocumentCategories = createAsyncActionWithTreeContext(
  types.FETCH_CATEGORIES,
  api.documents.getDocumentsCategories
)
export const createDocumentCategory = createAsyncActionWithTreeContext(
  types.CREATE_DOCUMENT_CATEGORY,
  api.documents.createDocumentCategory
)
export const translateDocumentCategory = createAsyncActionWithTreeContext(
  types.TRANSLATE_DOCUMENT_CATEGORY,
  api.documents.translateDocumentCategory
)
export const archiveDocumentCategory = createAsyncActionWithTreeContext(
  types.ARCHIVE_DOCUMENT_CATEGORY,
  api.documents.archiveDocumentCategory
)
export const updateDocumentCategory = createAsyncActionWithTreeContext(
  types.UPDATE_DOCUMENT_CATEGORY,
  api.documents.updateDocumentCategory
)
export const createDocumentCategoryVariant = createAsyncActionWithTreeContext(
  types.CREATE_DOCUMENT_CATEGORY_VARIANT,
  api.documents.createDocumentCategoryVariant
)

// ===== Document sub category ===== //
export const createDocumentSubCategory = createAsyncActionWithTreeContext(
  types.CREATE_DOCUMENT_SUB_CATEGORY,
  api.documents.createDocumentSubCategory
)
export const updateDocumentSubCategory = createAsyncActionWithTreeContext(
  types.UPDATE_DOCUMENT_SUB_CATEGORY,
  api.documents.updateDocumentSubCategory
)
export const archiveDocumentSubCategory = createAsyncActionWithTreeContext(
  types.ARCHIVE_DOCUMENT_SUB_CATEGORY,
  api.documents.archiveDocumentSubCategory
)
export const translateDocumentSubCategory = createAsyncActionWithTreeContext(
  types.TRANSLATE_DOCUMENT_SUB_CATEGORY,
  api.documents.translateDocumentSubCategory
)
// export const createDocumentSubCategoryVariant = createAsyncActionWithTreeContext(
//   types.CREATE_DOCUMENT_SUB_CATEGORY_VARIANT,
//   api.documents.createDocumentSubCategoryVariant
// )

// ===== Document local amendments ===== //
export const fetchLocalAmendment = createAsyncActionWithTreeContext(
  types.FETCH_LOCAL_AMENDMENT,
  api.documents.fetchLocalAmendment
)
export const createLocalAmendment = createAsyncActionWithTreeContext(
  types.CREATE_LOCAL_AMENDMENT,
  api.documents.createLocalAmendment
)
export const updateLocalAmendment = createAsyncActionWithTreeContext(
  types.UPDATE_LOCAL_AMENDMENT,
  api.documents.updateLocalAmendment
)
export const archiveLocalAmendment = createAsyncActionWithTreeContext(
  types.ARCHIVE_LOCAL_AMENDMENT,
  api.documents.archiveLocalAmendment
)
