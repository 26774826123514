import { Form, Checkbox, Input, Radio, Select, Switch, TreeSelect } from "antd"
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { boolean } from "boolean"

import CustomLinkInput from "./CustomLinkInput"
import DateInput from "./DateInput"
import ExtendedRichTextEditor from "./rich-text/ExtendedRichTextEditor"
import FileDragAndDrop from "./FileDragAndDrop"
import IconPicker from "./IconPicker"
import MultiSelectAll from "./MultiSelectAll"
import RichTextEditor from "./rich-text/RichTextEditor"
import UploadPicture from "./UploadPicture"

const FormItem = Form.Item
const RadioGroup = Radio.Group
const { TextArea } = Input

interface MakeFieldOptions {
  updateOnBlur?: boolean
  isSelect?: boolean
  isDateInput?: boolean
}

interface MakeFieldProps {
  itemKey?: string
  input: {
    value: any
    onChange: (value: any) => void
    onBlur: () => void
  }
  checked?: boolean
  meta: {
    touched: boolean
    invalid: boolean
    error?: string
  }
  defaultValue?: any | null
  disabled?: boolean
  children?: ReactNode
  hasFeedback?: boolean
  label?: string
  inputDataType?: any // you might want to specify a more specific type here
  disableValidation?: boolean
  disableDebounce?: boolean
  [key: string]: any // for the rest props
}

// TODO: Remove custom disableValidation logic -- AntDesign has fixed this in a minor update
// The "disableValidation" prop is a custom prop that can be passed to <Field> in order for the input component not to be wrapped in Ant Design <FormItem>
export const makeField =
  (
    Component: React.ComponentType<any>,
    { updateOnBlur = false, isSelect = false, isDateInput = false }: MakeFieldOptions = {}
  ) =>
  ({
    itemKey,
    input,
    checked,
    meta,
    defaultValue,
    disabled,
    children,
    hasFeedback,
    label,
    inputDataType,
    disableValidation,
    disableDebounce,
    ...rest
  }: MakeFieldProps) => {
    const hasError = useMemo(() => meta.touched && meta.invalid, [meta])
    const initialValue =
      input.value === "" && defaultValue !== undefined ? defaultValue : input.value
    const [internalValue, setInternalValue] = useState(initialValue || null)

    const onChange = useCallback((event: any, _value: any, _prevValue: any) => {
      if (event) {
        event.preventDefault()
        setInternalValue(event.currentTarget.value || null)
      }
    }, [])

    useEffect(() => {
      if (updateOnBlur && input.value && internalValue === null) {
        input.onChange(input.value)
        setInternalValue(input.value)
      }
    }, [input.value, internalValue, input])

    const selectProp = isSelect
      ? {
          onBlur: () => input.onBlur(),
        }
      : null

    const value = () => {
      if (isSelect) return initialValue || defaultValue

      return updateOnBlur ? internalValue : input.value || null
    }

    const ComponentWithProps = isDateInput ? (
      <Component
        {...input}
        key={itemKey}
        disabled={disabled}
        defaultValue={initialValue || defaultValue}
      />
    ) : (
      <Component
        {...input}
        key={itemKey}
        {...selectProp}
        {...rest}
        onChange={updateOnBlur ? onChange : input.onChange}
        label={label}
        children={children}
        value={value()}
        disabled={disabled}
        defaultValue={initialValue || defaultValue}
        checked={boolean(updateOnBlur ? internalValue : input.value)}
      />
    )

    if (disableValidation) return ComponentWithProps

    return (
      <FormItem
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        key={itemKey}
      >
        {ComponentWithProps}
      </FormItem>
    )
  }

export const InputField = makeField(Input, { updateOnBlur: true })
export const RadioGroupField = makeField(RadioGroup)
export const SelectField = makeField(Select, { isSelect: true })
export const TreeSelectField = makeField(TreeSelect)
export const CheckboxField = makeField(Checkbox)
export const TextAreaField = makeField(TextArea, { updateOnBlur: true })
export const SwitchField = makeField(Switch)
export const RichTextField = makeField(RichTextEditor)
export const ExtendedRichTextField = makeField(ExtendedRichTextEditor)
export const MultiSelectAllField = makeField(MultiSelectAll)
export const DateField = makeField(DateInput, { isDateInput: true })
export const FileDragAndDropField = makeField(FileDragAndDrop)
export const LinkInput = makeField(CustomLinkInput)
export const UploadPictureField = makeField(UploadPicture)
export const IconPickerField = makeField(IconPicker)
