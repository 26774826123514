import { useEffect, useState } from "react"
import { FormattedMessage, injectIntl, IntlShape, useIntl } from "react-intl"
import { Upload, notification } from "antd"
import { RcFile, UploadFile } from "antd/es/upload"
import { UploadRequestOption } from "rc-upload/es/interface"
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons"
import { removeFileExtension } from "src/components/helpers"

const { Dragger } = Upload

export type FileDragAndDropUploadRequestOption = UploadRequestOption

export type FileDragAndDropProps = {
  limit?: number
  multiple?: boolean
  value?: { fileList: RcFile[] }
  defaultValue?: { fileList: RcFile[] }
  onChange: (value: any) => void
  customUploadRequest: (thing: UploadRequestOption) => Promise<any>
  acceptedFileType?: string
  acceptedFileExtensions?: string[]
}

const FileDragAndDrop = (props: FileDragAndDropProps) => {
  const [uploading, setUploading] = useState(false)
  const [thumbUrl, setThumbUrl] = useState<string | undefined>(undefined)
  const { value, onChange, ...restProps } = props
  const [defaultFileList, setDefaultFileList] = useState<RcFile[]>([])
  const [filesToProcess, setFilesToProcess] = useState<RcFile[]>([])
  const fileList = value ? value.fileList || defaultFileList : defaultFileList
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([])
  const intl = useIntl()

  useEffect(() => {
    if (props.defaultValue && props.defaultValue.fileList) {
      setDefaultFileList(props.defaultValue.fileList)
    }
  }, [props.defaultValue])

  useEffect(() => {
    if (uploadedFiles.length === 0) return
    if (uploadedFiles.length !== filesToProcess.length) return

    onChange({
      fileList: [...defaultFileList, ...uploadedFiles],
    })
    setFilesToProcess([])
    setUploadedFiles([])
  }, [uploadedFiles])

  const onSuccess = (result: any, _media: any) => {
    setUploading(false)

    return result
  }
  const uploadFile = (e: UploadRequestOption) => {
    const { customUploadRequest } = props
    setUploading(true)
    e.onSuccess = onSuccess

    customUploadRequest(e).then((result) => {
      const fileWithModifiedUploadPercentage = renameFile(e.file as unknown as UploadFile, result)

      setUploadedFiles((uploadedFiles) => [...uploadedFiles, fileWithModifiedUploadPercentage])
    })
  }

  const getBase64 = (img: Blob) => {
    const reader = new window.FileReader()
    reader.onload = () => setThumbUrl(reader.result as string)
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile, fileList: RcFile[]): boolean | string => {
    const { acceptedFileType, acceptedFileExtensions } = props
    const fileExtension = file.name.split(".").pop() ?? ""
    const allHandledFilesLength = fileList.length + defaultFileList.length + uploadedFiles.length

    if (props.limit && props.limit < allHandledFilesLength) {
      notification.error({
        message: intl.formatMessage({ id: "input.fileUploadDragAndDrop.limitTitle" }),
        description: intl.formatMessage({
          id: "message.limitReached.description",
        }),
        duration: 8,
      })
      return false
    }

    const acceptedFile =
      acceptedFileType || acceptedFileExtensions
        ? acceptedFileType?.includes(file.type.split("/")[1]) ||
          acceptedFileExtensions?.includes(fileExtension)
        : "all"
    if (acceptedFile === false) {
      notification.error({
        message: intl.formatMessage({ id: "message.invalidFileType.message" }),
        description: intl.formatMessage({
          id: "message.invalidFileType.description",
        }),
        duration: 8,
      })
      return Upload.LIST_IGNORE
    }
    if (file?.type?.startsWith("image")) {
      getBase64(file)
    }
    setFilesToProcess((currentFiles) => [...currentFiles, file])
    return !!acceptedFile
  }

  const renameFile = (
    file: UploadFile,
    result: { media: { url: string; name: string } }
  ): UploadFile => {
    return {
      ...file,
      response: result,
      url: result.media.url,
      name: result.media.name,
      uid: removeFileExtension(result.media.url),
      thumbUrl: thumbUrl,
    }
  }
  const singleUpload = props.multiple === false
  const limitReached = singleUpload && fileList.length > 0

  return (
    <Dragger
      {...restProps}
      multiple={!singleUpload}
      accept={props.acceptedFileType || undefined}
      showUploadList={false}
      customRequest={uploadFile}
      defaultFileList={defaultFileList}
      fileList={fileList}
      onChange={() => {}}
      beforeUpload={beforeUpload}
      disabled={limitReached}
      onDrop={(_e) => {}}
      data-testid="FileDragAndDrop"
    >
      <p className="ant-upload-drag-icon">
        {uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}
      </p>
      <p className="ant-upload-text">
        {limitReached ? (
          <FormattedMessage id="input.fileUploadDragAndDrop.limitTitle" />
        ) : (
          <FormattedMessage id="input.fileUploadDragAndDrop.title" />
        )}
      </p>
      {singleUpload || props.limit ? null : (
        <p className="ant-upload-hint">
          <FormattedMessage id="input.fileUploadDragAndDrop.description" />
        </p>
      )}
    </Dragger>
  )
}

export default FileDragAndDrop
