import { message, notification } from "antd"
import React, { useEffect } from "react"

import Breadcrumbs from "src/components/layout/Breadcrumbs"
import Header from "src/components/layout/Header"
import Navigation from "src/components/layout/Navigation"
import Search from "src/components/layout/Search"
import Sidebar from "src/components/layout/Sidebar"

import "./style.less"
import {useSelector} from "react-redux";
import selectors from "src/store/selectors";

export default function ResponsiveLayout({ routes, isDesktop, user, children }) {
  useEffect(() => {
    message.config(getMessageConfig(isDesktop))
    notification.config(getNotificationConfig(isDesktop))
  }, [isDesktop])

  const menuSize = useSelector(selectors.uiSelectors.getMenuSize)

  return (
    <>
      <Header className="responsive-layout__header" user={user} input={<Search />}>
        {isDesktop && <Breadcrumbs routes={routes} />}
      </Header>
      {!isDesktop && <Navigation className="responsive-layout__navigation" routes={routes} tabs />}
      <div className="responsive-layout__mid-section">
        {isDesktop && <Sidebar className="responsive-layout__sidebar" />}
        <div className="responsive-layout__content-container" style={{width: `calc(100% - ${isDesktop ? menuSize : 0}px`}}>{children}</div>
      </div>
    </>
  )
}

const getMessageConfig = (isDesktop) => ({
  getContainer: () => document.querySelector('[role="main"]'),
  top: isDesktop ? 95 : 90,
  maxCount: 3,
})

const getNotificationConfig = (isDesktop) => ({
  placement: isDesktop ? "bottomRight" : "bottomLeft",
  bottom: isDesktop ? 55 : 60,
})
