import React from "react"
import { ReactSVG } from "react-svg"

export default function ProcessIcon({ icon }) {
  return (
    <ReactSVG
      src={`${process.env.PUBLIC_URL}/${icon}`}
      beforeInjection={(svg) => {
        const groupElement = svg.querySelector("g#Group")
        if (!groupElement) return
        if (groupElement.hasAttribute("stroke")) groupElement.setAttribute("stroke", "currentColor")
        if (groupElement.hasAttribute("fill") && !groupElement.hasAttribute("stroke"))
          groupElement.setAttribute("fill", "currentColor")
      }}
    />
  )
}
