import React from "react"

export default function EditorImage(props) {
  const { block, className, theme, direction, ...otherProps } = props
  const {
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    contentState,
    blockStyleFn,
    preventScroll,
    ...elementProps
  } = otherProps

  const { src, alt } = contentState.getEntity(block.getEntityAt(0)).getData()
  if (src.startsWith(window.location.origin)) {
    const relativeSrc = src.replace(window.location.origin, "")
    return <img {...elementProps} src={relativeSrc} alt={alt} />
  }
  return <img {...elementProps} src={src} alt={alt} />
}
