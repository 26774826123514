import { AxiosInstance, CancelToken } from "axios"
import { ApplicationContext } from "../api"

export const documentsEndpoints = (client: AxiosInstance) => {
  return {
    documents: {
      ...uncategorized(client),
      ...documentCategory(client),
      ...documentSubCategory(client),
      ...documents(client),
      ...documentLocalAmendments(client),
    },
  }
}

export type DocumentType = {
  applicationContext?: ApplicationContext
  opfDocument?: OpfDocument
  opfDocuments?: OpfDocument[]
  relatedDocuments?: any[]
  relatedProcesses?: any[]
  opfId?: string
  jiraApprovalId?: number
  chapters?: any[]
  notification?: any
  tree?: any | string
  opfDocumentCategory?: string
  slug?: string
  file?: any
  parentOpfId?: string
  parentId?: string
  moveOpfId?: number
  title?: string
  text?: string
  type?: string
  viewableByExternals?: boolean
  nextReviewDate?: Date
  // variantId?: string
  language?: string
}

type OpfDocument = {
  slug?: string
  id?: string
  documentVersionId?: number
  parentId?: string
  relatedDocuments?: any // opfDocument.relatedDocuments,
  relatedProcesses?: any // opfDocument.relatedProcesses,
  opfId?: string
  tree?: any // opfDocument.tree
  title?: string
  text?: string
  type?: string
  viewableByExternals?: boolean // opfDocument.viewableByExternals
  nextReviewDate?: Date // opfDocument.nextReviewDate
  nextReviewDateIsApplicable?: boolean
  chapters?: any[] // opfDocument.chapters
  links?: string[] | string // opfDocument.links
  documentAsFile?: any // opfDocument.documentAsFile
  language?: string
  // variantId?: string
  editorialVersion?: string
  documentOwner?: string
  classification?: string
  lastPageText?: string
}

const uncategorized = (client: AxiosInstance) => {
  return {
    fetchFlatTreeStructureDocuments: (
      { applicationContext, tree }: DocumentType,
      cancelToken: CancelToken
    ) =>
      client.post(
        "treestructure/getalldocuments",
        {
          applicationContext: tree ? { ...applicationContext, tree } : applicationContext,
        },
        { cancelToken }
      ),
    getDocumentsCategories: ({ applicationContext }: DocumentType, cancelToken: CancelToken) =>
      client.post("opfdocumentitems/getcategories", { applicationContext }, { cancelToken }),
    getDocument: ({ applicationContext, opfDocument }: DocumentType, cancelToken: CancelToken) =>
      client.post("opfdocumentitems/get", { applicationContext, opfDocument }, { cancelToken }),
    getDocumentBySlug: (
      {
        applicationContext,
        slug,
        level,
      }: { applicationContext: ApplicationContext; slug: string; level?: number },
      cancelToken: CancelToken
    ) =>
      client.post(
        "document/getbyslug",
        { applicationContext, slug, depth: level },
        { cancelToken }
      ),
    getLatestDraft: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document-publishing/load-latest-version", { opfDocument, applicationContext }),
    createDocumentDraft: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document-publishing/createdraft", {
        applicationContext,
        document: opfDocument,
      }),
    saveDraft: ({ applicationContext, opfDocument, chapters }: DocumentType) =>
      client.post("document-publishing/save-draft", {
        applicationContext,
        document: editDocumentDataTransferObject(opfDocument),
        chapters,
      }),
    createTranslation: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document-publishing/create-translation", {
        applicationContext,
        document: editDocumentDataTransferObject(opfDocument),
      }),
    autoTranslatedDocument: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/getDocumentAutoTranslation", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    autoTranslatedDocumentSubDetails: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/getSubDetailsAutoTranslation", {
        applicationContext,
        editDocumentSubDetailDto: editDocumentSubDetailsDataTransferObject(opfDocument),
      }),
    // createVariant: ({ applicationContext, opfDocument, chapters }: DocumentType) =>
    //   client.post("document-publishing/create-variant-draft", {
    //     applicationContext,
    //     document: editDocumentDataTransferObject(opfDocument),
    //     chapters,
    //   }),
    publishDocument: ({ applicationContext, opfId, jiraApprovalId/*, variantId*/ }: DocumentType) =>
      client.post("document-publishing/publish-version", {
        applicationContext,
        opfId,
        jiraApprovalId,
        // variantId,
      }),
    prePublishMinorVersion: ({
      applicationContext,
      opfId,
      notification,
      // variantId,
      opfDocument,
      chapters,
    }: DocumentType) =>
      client.post("document-publishing/pre-publish-minor-version", {
        applicationContext,
        opfId,
        notification: { ...notification, isMajorChange: false },
        // variantId,
        document: editDocumentDataTransferObject(opfDocument),
        chapters,
      }),
    prePublishMajorVersion: ({
      applicationContext,
      opfId,
      notification,
      // variantId,
      opfDocument,
      chapters,
    }: DocumentType) =>
      client.post("document-publishing/pre-publish-major-version", {
        applicationContext,
        opfId,
        notification: { ...notification, isMajorChange: true },
        // variantId,
        document: editDocumentDataTransferObject(opfDocument),
        chapters,
      }),
    moveDocument: ({ applicationContext, opfDocument, moveOpfId }: DocumentType) =>
      client.post("opfdocumentitems/move", {
        applicationContext,
        opfDocument,
        moveOpfId,
      }),
    uploadDocumentFile: ({ opfDocument, file, applicationContext }: DocumentType) => {
      const formData = new window.FormData()
      const headers = {
        "Content-Type": `multipart/form-data; boundary=--${opfDocument?.opfId}}`,
      }
      // const requestUrl = `opfdocumentitems/uploadfile?mediaFolder=${opfDocument?.opfId}&user=${applicationContext?.user?.upn}&variantId=${opfDocument?.variantId}`
      const requestUrl = `opfdocumentitems/uploadfile?mediaFolder=${opfDocument?.opfId}&user=${applicationContext?.user?.upn}`
      formData.append("file", file)
      return client.post(requestUrl, formData, { headers })
    },
    uploadRteFile: ({ opfDocument, file, applicationContext }: DocumentType) => {
      const formData = new window.FormData()
      const headers = {
        "Content-Type": `multipart/form-data; boundary=--${opfDocument?.opfId}}`,
      }
      // const requestUrl = `rte/uploadfile?mediaFolder=${opfDocument?.opfId}&user=${applicationContext?.user?.upn}&variantId=${opfDocument?.variantId}`
      const requestUrl = `rte/uploadfile?mediaFolder=${opfDocument?.opfId}&user=${applicationContext?.user?.upn}`
      formData.append("file", file)
      return client.post(requestUrl, formData, { headers })
    },
    getDocumentsForConfigurator: ({ applicationContext }: DocumentType, cancelToken: CancelToken) =>
      client.post("treestructure/getforconfigurator", { applicationContext }, { cancelToken }),
    saveForConfigurator: ({ applicationContext, opfDocuments }: DocumentType) =>
      client.post("opfdocumentitems/saveforconfigurator", {
        applicationContext,
        opfDocuments,
      }),
    removeDocumentFromConfigurator: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("opfdocumentitems/removefromconfigurator", {
        applicationContext,
        opfDocument,
      }),
    removeDocumentCategoryFromConfigurator: ({
      applicationContext,
      opfDocumentCategory,
    }: DocumentType) =>
      client.post("opfdocumentcategories/removefromconfigurator", {
        applicationContext,
        opfDocumentCategory,
      }),
    uploadCertificationFile: ({ file, applicationContext }: DocumentType) => {
      const formData = new window.FormData()
      const headers = {
        "Content-Type": `multipart/form-data;`,
      }

      const requestUrl = `opfcertificationitems/uploadfile?user=${applicationContext?.user?.upn}`
      formData.append("file", file)
      return client.post(requestUrl, formData, { headers })
    },
  }
}

const documents = (client: AxiosInstance) => {
  return {
    createDocument: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/create", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    createDocumentFromWord: ({ applicationContext, file, parentOpfId }: DocumentType) => {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("applicationContextJson", JSON.stringify(applicationContext))
      formData.append("parentOpfId", parentOpfId ?? "")
      return client.post("document/import-word-document", formData, {
        headers: { "Content-Type": "multipart/form-data;" },
      })
    },
    translateDocument: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/translate", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    updateDocument: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/update", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    updateRelatedItems: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/updaterelateditems", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    updateLinks: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/updatelinks", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    updateDocumentAsFile: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("document/updatedocumentasfile", {
        applicationContext,
        editDocumentDto: editDocumentDataTransferObject(opfDocument),
      }),
    archiveDocument: ({ applicationContext, opfDocument, jiraApprovalId }: DocumentType) =>
      client.post("document/archive", {
        applicationContext,
        editDocumentDto: {
          documentVersionId: opfDocument?.documentVersionId ?? opfDocument?.id,
          slug: opfDocument?.slug,
          jiraApprovalId,
        },
      }),
    // createDocumentVariant: ({ applicationContext, opfDocument }: DocumentType) =>
    //   client.post("document/createvariant", {
    //     applicationContext,
    //     editDocumentDto: editDocumentDataTransferObject(opfDocument),
    //   }),
    fetchDocumentChangelog: ({ applicationContext, opfId, language }: DocumentType) =>
      client.post("changelog/read-document-changelog", {
        applicationContext,
        opfId,
        language,
      }),
  }
}

const documentCategory = (client: AxiosInstance) => {
  return {
    createDocumentCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentcategory/create", {
        applicationContext,
        editDocumentCategoryDto: editDocumentCategoryDataTransferObject(opfDocument),
      }),
    updateDocumentCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentcategory/update", {
        applicationContext,
        editDocumentCategoryDto: editDocumentCategoryDataTransferObject(opfDocument),
      }),
    translateDocumentCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentcategory/translate", {
        applicationContext,
        editDocumentCategoryDto: editDocumentCategoryDataTransferObject(opfDocument),
      }),
    archiveDocumentCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentcategory/archive", {
        applicationContext,
        editDocumentCategoryDto: editDocumentCategoryDataTransferObject(opfDocument),
      }),
    // createDocumentCategoryVariant: ({ applicationContext, opfDocument }: DocumentType) =>
    //   client.post("documentcategory/createvariant", {
    //     applicationContext,
    //     editDocumentCategoryDto: editDocumentCategoryDataTransferObject(opfDocument),
    //   }),
  }
}

const documentSubCategory = (client: AxiosInstance) => {
  return {
    createDocumentSubCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentsubcategory/create", {
        applicationContext,
        editDocumentSubCategoryDto: editDocumentSubCategoryDataTransferObject(opfDocument),
      }),
    updateDocumentSubCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentsubcategory/update", {
        applicationContext,
        editDocumentSubCategoryDto: editDocumentSubCategoryDataTransferObject(opfDocument),
      }),
    archiveDocumentSubCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentsubcategory/archive", {
        applicationContext,
        editDocumentSubCategoryDto: editDocumentSubCategoryDataTransferObject(opfDocument),
      }),
    translateDocumentSubCategory: ({ applicationContext, opfDocument }: DocumentType) =>
      client.post("documentsubcategory/translate", {
        applicationContext,
        editDocumentSubCategoryDto: editDocumentSubCategoryDataTransferObject(opfDocument),
      }),
    // createDocumentSubCategoryVariant: ({ applicationContext, opfDocument }: DocumentType) =>
    //   client.post("documentsubcategory/createvariant", {
    //     applicationContext,
    //     editDocumentSubCategoryDto: editDocumentSubCategoryDataTransferObject(opfDocument),
    //   }),
  }
}

type LocalAmendment = {
  applicationContext: ApplicationContext
  documentId: string
  documentVersionId: string
  visibleFor: LocalAmendmentVisibleFor
  viewModel?: any[]
}

type LocalAmendmentVisibleFor = {
  country: string
  account: string
}

const
  documentLocalAmendments = (client: AxiosInstance) => {
  return {
    fetchLocalAmendment: ({
      applicationContext,
      documentId,
      documentVersionId,
      visibleFor,
    }: LocalAmendment) =>
      client.post("local-amendments/read", {
        applicationContext,
        documentId,
        documentVersionId,
        visibleFor,
      }),
    createLocalAmendment: ({
      applicationContext,
      documentId,
      documentVersionId,
      visibleFor,
      viewModel,
    }: LocalAmendment) =>
      client.post("local-amendments/create", {
        applicationContext,
        documentId,
        documentVersionId,
        visibleFor,
        viewModel,
      }),
    updateLocalAmendment: ({
      applicationContext,
      documentId,
      documentVersionId,
      visibleFor,
      viewModel,
    }: LocalAmendment) =>
      client.post("local-amendments/update", {
        applicationContext,
        documentId,
        documentVersionId,
        visibleFor,
        viewModel,
      }),
    archiveLocalAmendment: ({
      applicationContext,
      id,
    }: {
      applicationContext: ApplicationContext
      id: string
    }) =>
      client.post("local-amendments/delete", {
        applicationContext,
        localAmendmentId: id,
      }),
  }
}

const editDocumentDataTransferObject = (opfDocument?: OpfDocument) => ({
  documentVersionId: opfDocument?.documentVersionId ?? opfDocument?.id,
  parentId: opfDocument?.parentId,
  relatedDocuments: opfDocument?.relatedDocuments,
  relatedProcesses: opfDocument?.relatedProcesses,
  opfId: opfDocument?.opfId,
  tree: opfDocument?.tree,
  title: opfDocument?.title,
  text: opfDocument?.text,
  type: opfDocument?.type,
  viewableByExternals: opfDocument?.viewableByExternals,
  nextReviewDate: opfDocument?.nextReviewDate,
  nextReviewDateIsNotApplicable: !opfDocument?.nextReviewDateIsApplicable,
  chapters: opfDocument?.chapters,
  links: opfDocument?.links,
  documentAsFile: opfDocument?.documentAsFile,
  language: opfDocument?.language,
  // variantId: opfDocument?.variantId,
  editorialVersion: opfDocument?.editorialVersion,
  documentOwner: opfDocument?.documentOwner,
  classification: opfDocument?.classification,
  lastPageText: opfDocument?.lastPageText,
})

const editDocumentCategoryDataTransferObject = (opfDocument?: OpfDocument) => ({
  opfId: opfDocument?.opfId,
  documentCategoryVersionId: opfDocument?.id,
  tree: opfDocument?.tree,
  text: opfDocument?.text,
  title: opfDocument?.title,
  // variantId: opfDocument?.variantId,
})

const editDocumentSubDetailsDataTransferObject = (opfDocument?: OpfDocument) => ({
  title: opfDocument?.title,
  text: opfDocument?.text,
  language: opfDocument?.language,
})

const editDocumentSubCategoryDataTransferObject = (opfDocument?: OpfDocument) => ({
  opfId: opfDocument?.opfId,
  documentSubCategoryVersionId: opfDocument?.id,
  parentId: opfDocument?.parentId,
  tree: opfDocument?.tree,
  text: opfDocument?.text,
  title: opfDocument?.title,
  // variantId: opfDocument?.variantId,
})
