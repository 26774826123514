import { createAsyncAction } from "../utils"

import { api as externalApi } from "../../services/api"
import types from "./types"

const api = externalApi()

export const fetchNews = createAsyncAction(types.FETCH_ALL_NEWS, api.news.getAllNews)
export const fetchNewsById = createAsyncAction(types.FETCH_NEWS_BY_ID, api.news.getNews)
export const createNews = createAsyncAction(types.CREATE_NEWS, api.news.createNews)

