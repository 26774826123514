import { ASYNC, SYNC, actionTypes} from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type OpfCommunityActionTypes = {
  opfCommunity: {
    FETCH_OPF_COMMUNITIES: AsyncRequestStatus,
    SET_FILTER_VALUE: string
  }
}

const types = actionTypes({
  opfCommunity: {
    FETCH_OPF_COMMUNITIES: ASYNC,
    SET_FILTER_VALUE: SYNC
  }
}) as OpfCommunityActionTypes

export default types.opfCommunity


export type TOptCommunity = {
  country: string
  opfLead: string
  opfLeadSupport?: string[]
  region: string
}

export type TCountryFunctionOwners = {
  "countryCode": string,
  "functionOwner": {
    "name": string
    "email": string
  }
}

export type TOptContentOwnership = {
  global: boolean
  type: string[]
  title: string
  opfId: string
  id: string
  globalFunctionOwner?: {
    email?: string;
    name?: string;
  }
  countryFunctionOwners?: TCountryFunctionOwners[]
  // computed
  countryTableData?: IOwnershipTableItem[]
}

export type OpfCommunityState = {
  opfCommunities: TOptCommunity[]
  opfCommunitiesContentOwnership: {
    functions: TOptContentOwnership[]
  }
  loading: boolean
  error?: boolean,
  filters: {
    functionValue?: string,
    countryValues?: string[],
    levelValues?: string[],
  }
}

export interface IOwnershipTableItem {
  country: string
  countryCode: string
  ownerName?: string
  ownerEmail?: string
}
