import { produce } from "immer"
import camelCase from "lodash/camelCase";
import types from "./types"

export type HomeLinkType = {
  title: string;
  text?: string;
  link: string;
}

export type HomeState = {
  title: string
  searchKeyWords: string[]
  repositories: Record<string, string & HomeLinkType | undefined>
  referenceLinks: {
    sharepointsites?: {
      title: string
      text: string
      links: HomeLinkType[]
    }
    links: HomeLinkType[]
  },
  loading: boolean
  error?: boolean
}

export const initialState: HomeState = Object.freeze({
  title: '',
  searchKeyWords: [],
  repositories: {},
  referenceLinks: {
    sharepointsites: {
      title: "",
      text: "",
      links: []
    },
    links: []
  },
  loading: false
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_HOMEPAGE.REQUEST:
        writableState.loading = true
        writableState.error = false
        break
      case types.FETCH_HOMEPAGE.SUCCESS:
        writableState.title = action.payload.homepageContent.title
        writableState.searchKeyWords = action.payload.homepageContent.searchKeyWords || []
        if (action.payload.homepageContent.repositories) {
          writableState.repositories = Object.keys(action.payload.homepageContent.repositories).reduce((acc, currKey) => {
            return {
              ...acc,
              [camelCase(currKey)]: action.payload.homepageContent.repositories[currKey]
            }
          }, {})
        }

        writableState.referenceLinks = action.payload.homepageContent.referencelinks
        writableState.loading = false
        writableState.error = false
        break
      case types.FETCH_HOMEPAGE.FAILURE:
        writableState.loading = false
        writableState.error = true
        break
      default:
        return writableState
    }
    return writableState
  })
}
