import { produce } from "immer"
import types, { OpfCommunityState } from "./types"

export const initialState: OpfCommunityState = Object.freeze({
  opfCommunities: [],
  opfCommunitiesContentOwnership: {
    functions: []
  },
  loading: false,
  filters: {
    functionValue: undefined,
    countryValues: undefined,
    levelValues: undefined,
  }
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_OPF_COMMUNITIES.REQUEST:
        writableState.loading = true
        writableState.error = false
        break
      case types.FETCH_OPF_COMMUNITIES.SUCCESS:
        writableState.opfCommunities = action.payload?.opfCommunities || []
        writableState.opfCommunitiesContentOwnership = action.payload?.opfCommunitiesContentOwnership || []
        writableState.loading = false
        writableState.error = false
        break
      case types.FETCH_OPF_COMMUNITIES.FAILURE:
        writableState.loading = false
        writableState.error = true
        break
      case types.SET_FILTER_VALUE:
        writableState.filters[action.payload.filterName as keyof OpfCommunityState['filters']] = action.payload.filterValue
        break
      default:
        return writableState
    }
    return writableState
  })
}
