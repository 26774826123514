import { produce } from "immer"
import { orderBy } from "lodash"
import types from "./types"

export interface INews {
  id: string
  newsType: string
  opfId: string
  publishedBy: string
  publishedDate: string
  text: string
  title: string
  type: string
}

export type NewsState = {
  allNews: INews[]
  fetchingAllNews: boolean
  errorAllNews?: boolean
  newsById: INews | null
  fetchingNewsById: boolean
  errorNewsById?: boolean
}

export const initialState: NewsState = Object.freeze({
  allNews: [],
  fetchingAllNews: false,
  newsById: null,
  fetchingNewsById: false
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_ALL_NEWS.REQUEST:
        writableState.allNews = [];
        writableState.fetchingAllNews = true
        writableState.errorAllNews = false
        break
      case types.FETCH_ALL_NEWS.SUCCESS:
        writableState.allNews = orderBy(action.payload.news, ['publishedDate'], ['desc'])
        writableState.fetchingAllNews = false
        writableState.errorAllNews = false
        break
      case types.FETCH_ALL_NEWS.FAILURE:
        writableState.allNews = [];
        writableState.fetchingAllNews = false
        writableState.errorAllNews = true
        break
      case types.FETCH_NEWS_BY_ID.REQUEST:
        writableState.newsById = null
        writableState.fetchingNewsById = true
        writableState.errorNewsById = false
        break
      case types.FETCH_NEWS_BY_ID.SUCCESS:
        writableState.newsById = action.payload.news?.[0]
        writableState.fetchingNewsById = false
        writableState.errorNewsById = false
        break
      case types.FETCH_NEWS_BY_ID.FAILURE:
        writableState.newsById = null;
        writableState.fetchingNewsById = false
        writableState.errorNewsById = true
        break
      default:
        return writableState
    }
    return writableState
  })
}
