import { produce } from "immer"
import types, {StatisticsState} from "./types"

export const initialState: StatisticsState = Object.freeze({
  links: [],
  loading: false,
})

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  return produce(state, (writableState) => {
    switch (action.type) {
      case types.FETCH_STATISTICS.REQUEST:
        writableState.loading = true
        writableState.error = false
        break
      case types.FETCH_STATISTICS.SUCCESS:
        writableState.links = action.payload?.statisticsContent?.links || []
        writableState.loading = false
        writableState.error = false
        break
      case types.FETCH_STATISTICS.FAILURE:
        writableState.loading = false
        writableState.error = true
        break
      default:
        return writableState
    }
    return writableState
  })
}
