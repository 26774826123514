import { FormattedMessage, injectIntl } from "react-intl"
import React, { Component, lazy } from "react"
import { getFormValues, propTypes, reduxForm } from "redux-form"
import { has, pick } from "lodash"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { notification } from "antd"
import titleize from "titleize"

import { ACTIONS, EMPTY_HTML } from "../../globals"
import { bindActions, bindSelectors } from "src/store/utils"
import StepHandler from "../StepHandler"
import actions from "src/store/actions"
import { linkToFile } from "src/components/helpers"
import selectors from "src/store/selectors"


const DocumentsStep = lazy(() => /* webpackPrefetch: -1 */ import("../steps/DocumentsStep"))

const itemFields = [
  "tree",
  "type",
  "title",
  "viewableByExternals",
  "editorialVersion",
  "documentOwner",
  "nextReviewDate",
  "reviewDate",
  "relatedProcesses",
  "relatedDocuments",
  "text",
]

export class DocumentForm extends Component {
  validate = () => [...itemFields, "newOrder"].forEach(this.props.touch)

  getUploadPdfStep = () => {
    const { formValues, mapToMediaLinks, item } = this.props
    const documents =
      formValues && formValues.documents ? mapToMediaLinks(formValues.documents.fileList) : null
    return [
      {
        title: "form.step.uploadPdf.title",
        component: DocumentsStep,
        props: {
          item,
          documents,
          multiple: false,
          cardTitle: (
            <FormattedMessage
              id="form.step.pdf.title"
              values={{ type: () => titleize(item.type) }}
            />
          ),
          cardDescription: (
            <FormattedMessage
              id="form.step.pdf.description"
              values={{ type: () => titleize(item.type) }}
            />
          ),
          removeAttachment: this.removeAttachment,
          moveAttachmentUp: this.moveAttachmentUp,
          type: "uploadFile",
          limit: 1,
          acceptedFileExtensions: ["pdf"],
          customUploadRequest: this.customUploadRequest,
        },
      },
    ]
  }

  componentDidMount() {
    const { item, initialize, change, mode } = this.props
    if (!item) return

    if (mode === ACTIONS.UPLOAD_PDF) {
      const values = pick(itemFields, item)
      initialize({
        ...values,
        text: item.text === "" ? EMPTY_HTML : item.text,
      })
    }

    if (mode === ACTIONS.UPLOAD_PDF) {
      const fileList = [item.documentAsFile].map(linkToFile).filter(Boolean)
      change("documents", { fileList })
      fileList.forEach((file) => {
        change(`documentNames.${file.uid}.name`, file.name)
      })
    }
  }

  customUploadRequest = ({ onSuccess, onError, file }) => {
    const { uploadDocumentFile, item } = this.props

    return uploadDocumentFile({
      opfDocument: item,
      file
    })
      .then((result) => onSuccess(result, result.media))
      .catch(onError)
  }

  removeAttachment = (fileListType, uid) => {
    const { change, formValues } = this.props
    const fileList = formValues[fileListType].fileList.filter((file) => file.uid !== uid)

    change(fileListType, { ...formValues[fileListType], fileList })
  }

  moveAttachmentUp = (fileListType, currentIndex) => {
    if (currentIndex === 0) return null

    const { change, formValues } = this.props

    const fileList = [...formValues[fileListType].fileList]
    const newIndex = currentIndex - 1
    const _file = fileList[newIndex]
    fileList[newIndex] = fileList[currentIndex]
    fileList[currentIndex] = _file

    change(fileListType, { ...formValues[fileListType], fileList })
  }

  render() {
    const { valid, mode, submit, submitting } = this.props
    let steps = []
    switch (mode) {
      case ACTIONS.UPLOAD_PDF:
        steps = this.getUploadPdfStep()
        break
      default:
    }
    return (
      <StepHandler
        onFinalStepSubmit={submit}
        steps={steps}
        overview={mode !== ACTIONS.EDIT_RELATIONS}
        valid={valid}
        validate={this.validate}
        submitting={submitting}
      />
    )
  }
}

export const onSubmit = async (values, dispatch, props) => {
  const {
    mode,
    item,
    closeModal,
    intl,
    updateDocumentAsFile,
  } = props
  const { documentNames } = values
  const documentFiles = has(values, "documents.fileList") ? values.documents.fileList : []
  const getUpdatedDocumentName = (file) => {
    const updatedDocument = documentNames ? documentNames[file.uid] : null
    return updatedDocument ? updatedDocument.name : file.response.media.name
  }

  if (!documentFiles?.length) {
    notification.info({ message: 'File is not uploaded' })
    return;
  }

  let submission
  switch (mode) {
    case ACTIONS.UPLOAD_PDF:
      const title = getUpdatedDocumentName(documentFiles[0]);
      const documentAsFile = documentFiles.length
        ? {
            title,
            url: documentFiles[0].url,
            type: "pdf",
          }
        : {}


      submission = updateDocumentAsFile({
        ...item,
        title: title,
        file: documentAsFile.url,
      })

      break
    default:
      submission = Promise.resolve()
      break
  }
  return submission.then(closeModal).then(() => {
    const message = intl?.formatMessage({ id: "message.savedChanges" })
    notification.success({ message })
  })
}

DocumentForm.propTypes = {
  mode: PropTypes.string,
  formValues: PropTypes.object,
  getUserItemRights: PropTypes.func,
  mapToMediaLinks: PropTypes.func,
  closeModal: PropTypes.func,
  uploadDocumentFile: PropTypes.func,
  ...propTypes,
}
const form = "certificationMeta"
const ConnectedDocumentForm = reduxForm({ form, onSubmit })(DocumentForm)
const mapStateToProps = bindSelectors({
  formValues: getFormValues(form),
  getUserItemRights: selectors.userSelectors.getUserItemRights,
  mapToMediaLinks: selectors.mapToMediaLinks,
  currentLanguage: selectors.localeSelectors.getCurrentLanguage,
})
const mapDispatchToProps = bindActions({
  closeModal: actions.uiActions.closeFormModal,
  updateDocumentAsFile: actions.certificationActions.createCertification,
  uploadDocumentFile: actions.certificationActions.uploadFile,
  fetchDocument: actions.certificationActions.fetchCertificationBySlug,
})
const IntlDocumentForm = injectIntl(ConnectedDocumentForm)
export default connect(mapStateToProps, mapDispatchToProps)(IntlDocumentForm)
