import {ASYNC, actionTypes, SYNC} from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type UniqNumberGeneratorActionTypes = {
  uniqNumberGenerator: {
    FETCH_UNIQ_NUMBER: AsyncRequestStatus,
    SAVE_UNIQ_NUMBER: AsyncRequestStatus,
    RESET_NUMBER_VALUE: string,
  }
}

const types = actionTypes({
  uniqNumberGenerator: {
    FETCH_UNIQ_NUMBER: ASYNC,
    SAVE_UNIQ_NUMBER: ASYNC,
    RESET_NUMBER_VALUE: SYNC
  }
}) as UniqNumberGeneratorActionTypes

export default types.uniqNumberGenerator

export type TDictionaryValue = {
  value: string
  name: string
}

export type UniqNumberState = {
  loading: boolean
  error?: boolean,
  number?: string
  filters: {
    opfLocalizations?: TDictionaryValue[],
    documentTypes?: TDictionaryValue[],
    functions?: TDictionaryValue[],
  }
}
