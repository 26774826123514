import { useEffect, useState, ReactNode, useCallback } from "react"
import { AuthProvider, AuthProviderProps } from "oidc-react"
import axios from "axios"
import Loading from "../Loading"
import AuthOidcConfigContext from "src/contexts/AuthOidcConfigContext"

type ConfigurationData = {
  authority: string
  clientId: string
  scopes: string
  signOutRedirect: string
}

const allowedOrigins = [
  window.location.origin,
  "https://login.microsoftonline.com"
]

export const checkRedirectVulnerability = (path: string) => {
  try {
    const origin = window.location.origin
    const storedUrl = new URL(path, origin)
    // Ensure that the stored path has the same origin as the current location
    return allowedOrigins.includes(storedUrl.origin)
  } catch (error) {
    console.log(error)
    return false
  }
}

export default function AuthOidcProvider({ children }: { children: ReactNode }): JSX.Element {
  const [oidcConfig, setOidcConfig] = useState<AuthProviderProps>()

  const location = window.location

  const config = {
    redirectUri: `${location.origin}`,
    silentRedirectUri: `${location.origin}/`,
    automaticSilentRenew: true,
    onBeforeSignIn() {
      /* istanbul ignore next */
      localStorage.setItem("stored-path", location.href)
    },
    autoSignIn: true,
    loadUserInfo: false,
    onSignIn() {
      /* istanbul ignore next */
      window.history.replaceState(null, "", " ")

      const storedPath = localStorage.getItem("stored-path")
      if (storedPath && checkRedirectVulnerability(storedPath)) {
        location.href = storedPath

        localStorage.removeItem("stored-path")
      }
    },
    onSignOut() {
      /* istanbul ignore next */
      localStorage.removeItem("stored-path")
    },
  }

  const handleConfiguration = useCallback(async () => {
    const { data }: { data: ConfigurationData } = await axios.get(
      "/api/oidc/frontend-configuration"
    )

    if (!data) return

    if (!checkRedirectVulnerability(data.authority)) {
      console.error("Invalid OIDC authority")
      return
    }

    setOidcConfig({
      postLogoutRedirectUri: data.signOutRedirect ?? `${location.origin}/account/signout`,
      authority: data.authority,
      clientId: data.clientId,
      scope: data.scopes,
    })

    localStorage.setItem("oidc.authority", data.authority)
    localStorage.setItem("oidc.clientId", data.clientId)
  }, [location.origin, location.href])

  useEffect(() => {
    handleConfiguration()
  }, [handleConfiguration])

  if (!oidcConfig) {
    return <Loading />
  }

  return (
    <AuthOidcConfigContext.Provider value={oidcConfig}>
      <AuthProvider
        redirectUri={config.redirectUri}
        silentRedirectUri={config.silentRedirectUri}
        automaticSilentRenew={config.automaticSilentRenew}
        onBeforeSignIn={config.onBeforeSignIn}
        autoSignIn={config.autoSignIn}
        loadUserInfo={config.loadUserInfo}
        onSignIn={config.onSignIn}
        onSignOut={config.onSignOut}
        postLogoutRedirectUri={oidcConfig.postLogoutRedirectUri}
        authority={oidcConfig.authority}
        clientId={oidcConfig.clientId}
        scope={oidcConfig.scope}
      >{children}</AuthProvider>
    </AuthOidcConfigContext.Provider>
  )
}
