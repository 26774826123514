import Icon, { CheckCircleOutlined, PictureOutlined } from "@ant-design/icons"
import { Badge, Empty, Input, Popover } from "antd"
import { injectIntl } from "react-intl"
import { isEmpty } from "lodash"
import { Scrollbars } from "react-custom-scrollbars"
import { useSelector } from "react-redux"
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"

import useActions from "src/hooks/useActions"
import useKeyPress from "src/hooks/useKeyPress"
import ProcessIcon from "src/components/opf-items/ProcessIcon"
import actions from "src/store/actions"
import selectors from "src/store/selectors"

import "./style.less"

const Search = Input.Search

export function IconPicker(props) {
  const { intl, onChange, value, disabled = false } = props
  const [visible, setVisibility] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)
  const [fetchIcons] = useActions([actions.itemActions.fetchIcons])
  const iconCollection = useSelector(selectors.itemSelectors.getIcons)
  const iconPickerRef = useRef()
  const topBarRef = useRef(null)

  const handleSearchChange = useCallback(
    (e) => {
      const { value } = e.target
      setSearchQuery(value || null)
    },
    [setSearchQuery]
  )

  const toggleIconPicker = useCallback(
    (show) => {
      if (show && isEmpty(iconCollection)) {
        fetchIcons().then(() => setVisibility(show))
        return
      }
      if (show === false) setSearchQuery(null)
      setVisibility(show)
    },
    [fetchIcons, iconCollection]
  )

  useKeyPress("Escape", () => toggleIconPicker(false))

  useEffect(() => {
    const handleClick = (e) => {
      const clickInsideIconPicker =
        iconPickerRef.current && iconPickerRef.current.contains(e.target)
      const clickInsideTopBar = topBarRef.current && topBarRef.current.contains(e.target)
      const clickInsidePopover =
        !isEmpty(e.target.classList) &&
        Array.from(e.target.classList).some(
          (className) => className.includes("ant-popover") || className.includes("icon-picker")
        )
      const clickSvg = ["svg", "path", "g", "i"].includes(e.target.tagName.toLowerCase())

      if (
        (clickInsideIconPicker || clickInsideTopBar || clickInsidePopover || clickSvg) === false
      ) {
        toggleIconPicker(false)
      }
    }
    if (visible) {
      window.addEventListener("click", handleClick)
    }
    return () => window.removeEventListener("click", handleClick)
  }, [toggleIconPicker, visible])

  const topBar = (
    <div style={{ display: "flex", alignItems: "center" }} ref={topBarRef}>
      <Search
        placeholder={intl.formatMessage({ id: "common.search" })}
        onSearch={setSearchQuery}
        onChange={handleSearchChange}
        value={searchQuery || ""}
        style={{ marginRight: "5px" }}
      />
    </div>
  )

  const filteredIconCollection = isEmpty(searchQuery)
    ? iconCollection
    : Object.entries(iconCollection).reduce((accum, value, collection) => {
        const [category, icons] = value
        const query = searchQuery.toLowerCase()
        if (category.toLowerCase().includes(query) || icons.some((icon) => icon.includes(query))) {
          return { ...accum, [category]: icons.filter((icon) => icon.includes(query)) }
        }
        return accum
      }, {})

  const renderIconCollection = (collection, index) => {
    const [category, icons = []] = collection
    return isEmpty(icons)
      ? []
      : icons.map((icon) => {
          const IconSvg = (props) => <ProcessIcon icon={icon} {...props} />
          const iconOption = (
            <div
              title={icon}
              className="icon-picker__option"
              onClick={() => (icon === value ? onChange(null) : onChange(icon))}
            >
              <Icon component={IconSvg} />
            </div>
          )
          return (
            <Fragment key={category + "_" + icon}>
              {value === icon ? (
                <Badge className="icon-picker__active-option" count={<CheckCircleOutlined />}>
                  {iconOption}
                </Badge>
              ) : (
                iconOption
              )}
            </Fragment>
          )
        })
  }

  const iconSelector = (
    <div className="icon-picker" ref={iconPickerRef}>
      <div className="icon-picker__collection">
        <Scrollbars autoHide={false}>
          <div className="icon-picker__collection-inner">
            {isEmpty(filteredIconCollection) ? (
              <Empty image={null} style={{ top: "50%", transform: "translate(-1%, 66%)" }} />
            ) : (
              Object.entries(filteredIconCollection).map(renderIconCollection)
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  )

  const icon = value ? (
    <Icon
      className="procedure__icon icon-picker__current-icon"
      component={(props) => <ProcessIcon icon={value} {...props} />}
      onClick={() => toggleIconPicker(!visible)}
      disabled={disabled}
    />
  ) : (
    <PictureOutlined
      className="procedure__icon icon-picker__current-icon"
      onClick={() => toggleIconPicker(!visible)}
      disabled={disabled}
      onMouseEnter={() => (isEmpty(iconCollection) ? fetchIcons() : null)}
    />
  )

  if (disabled) return icon

  return (
    <Popover
      overlayClassName="icon-picker-popover"
      arrowPointAtCenter
      trigger="click"
      placement="rightTop"
      content={iconSelector}
      title={topBar}
      open={visible}
      onVisibilityChange={toggleIconPicker}
    >
      {icon}
    </Popover>
  )
}

export default injectIntl(IconPicker)

// Where to find example https://localhost:44309/processes/supportprocesses/peoplemanagement/recruiting/approval
