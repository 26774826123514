import React, { Component } from "react"
import { isEmpty, without } from "lodash"
import PropTypes from "prop-types"
import { Select } from "antd"

import FormattedTitle from "src/components/FormattedTitle"
const { Option } = Select
const ALL = "all"

export class MultiSelectAll extends Component {
  handleChange = (value) => {
    const { onChange, options } = this.props
    const oldValue = this.props.value
    if (isEmpty(value)) return onChange([ALL])
    if (options.length === value.length) return onChange([ALL])
    if (oldValue.includes(ALL) && value.length > 1) return onChange(without(value, ALL))
    if (value.includes(ALL)) {
      onChange([ALL])
    } else {
      onChange(without(value, ALL))
    }
  }

  render() {
    const { value, options, ...rest } = this.props
    return (
      <Select {...rest} value={value} mode="multiple" onChange={this.handleChange}>
        <Option value={ALL}>
          <FormattedTitle id="common.all" />
        </Option>
        {options.map((option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  }
}

MultiSelectAll.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  onChange: PropTypes.func,
}
export default MultiSelectAll
