import { batch } from "react-redux"
import languages from "langs"

import * as configuratorActions from "./configurator/actions"
import * as documentsActions from "./documents/actions"
import * as itemActions from "./items/actions"
import * as localeActions from "./locale/actions"
import * as refinerActions from "./refiner/actions"
import * as uiActions from "./ui/actions"
import * as userActions from "./user/actions"
import * as modalActions from "./modal/actions"
import * as draftActions from "./drafts/actions"
import * as configActions from "./configs/actions"
import * as homeActions from "./home/actions"
import * as newsActions from "./news/actions"
import * as opfCommunityActions from "./opfCommunity/actions"
import * as certificationActions from "./certifications/actions"
import * as uniqNumberActions from "./uniqNumberGenerator/actions"
import * as statisticsActions from "./statistics/actions"

import { DEFAULT_LANGUAGE, INIT_STATUS } from "src/globals"

import selectors from "./selectors"
import { browserLanguage } from "./utils/helpers"

const getLanguageVariables = (dispatch, getState) => {
  const fetchSupportedLanguages = dispatch(localeActions.fetchLanguages())
  const rehydratedLanguageSetting = selectors.localeSelectors.getCurrentCachedLanguage(getState())
  const pathLanguageCode = window.location.pathname.split("/")[1].toLowerCase()

  const alreadyHasLanguageCode = pathLanguageCode
    ? languages.has("1", pathLanguageCode.split("-")[0] || "")
    : false

  let currentLanguage
  if (alreadyHasLanguageCode) {
    currentLanguage = pathLanguageCode
  } else {
    if (rehydratedLanguageSetting && rehydratedLanguageSetting.includes(DEFAULT_LANGUAGE)) {
      currentLanguage = browserLanguage.includes(DEFAULT_LANGUAGE)
        ? DEFAULT_LANGUAGE
        : browserLanguage
    } else {
      currentLanguage = rehydratedLanguageSetting || DEFAULT_LANGUAGE
    }
  }

  return {
    currentLanguage,
    alreadyHasLanguageCode,
    rehydratedLanguageSetting,
    fetchSupportedLanguages,
    pathLanguageCode,
    browserLanguage,
  }
}

/** fetch all the data required for showing the initial view of the application */
export const bootApplication = () => (dispatch, getState) => {
  if (selectors.uiSelectors.getInitializationFinished(getState())) return // prevent multiple initializations
  console.groupCollapsed("🚀 Initialization")
  // intialization started
  dispatch(uiActions.setAppInitStatus({ status: INIT_STATUS.LOADING }))

  console.groupCollapsed("🌐 Language")

  const languageVariables = getLanguageVariables(dispatch, getState)
  const {
    currentLanguage,
    alreadyHasLanguageCode,
    rehydratedLanguageSetting,
    fetchSupportedLanguages,
    pathLanguageCode, // only fetched for console log
    browserLanguage, // only fetched for console log
  } = languageVariables
  let languageToLoad

  console.log("alreadyHasLanguageCode:", alreadyHasLanguageCode)
  console.log("firstPathItem:", pathLanguageCode)
  console.log("rehydratedLanguageSetting:", rehydratedLanguageSetting)
  console.log("browserLanguage:", browserLanguage)
  console.log("currentLanguage:", currentLanguage)

  // get the user profile
  return dispatch(userActions.fetchUserProfile())
    .then(({ userProfile }) => {
      const { language = currentLanguage, upn } = userProfile
      // load language settings fallback: url -> userprofile -> browser language
      languageToLoad =
        language &&
        language !== currentLanguage &&
        !alreadyHasLanguageCode &&
        !rehydratedLanguageSetting
          ? language.startsWith(DEFAULT_LANGUAGE)
            ? DEFAULT_LANGUAGE
            : language
          : currentLanguage

      console.log("language from userProfile:", language)
      console.log("languageToLoad:", languageToLoad)
      console.groupEnd("🌐 Language")

      // get the user's favorite items in the background
      return batch(() => {
        console.groupCollapsed("👤 User")
        console.log("userProfile:", userProfile)
        if (upn) dispatch(itemActions.getFavorites())
        const currentRole = selectors.userSelectors.getCurrentRole(getState())
        console.info("currentRole:", currentRole)
        // get configurator data in the background (for tag titles etc.)
        dispatch(configuratorActions.fetchConfiguratorDataForCurrentRole())
        // get the configurator for the current user
        console.groupEnd("👤 User")
        return dispatch(userActions.fetchConfigurator())
      })
    })

    .then(() => fetchSupportedLanguages)
    .then(({ languages }) => {
      const supportedLanguageCodes = languages.map((language) => language.code)
      if (supportedLanguageCodes.includes(languageToLoad) && languageToLoad !== DEFAULT_LANGUAGE) {
        console.info(languageToLoad, "language is supported")
        return dispatch(localeActions.changeLanguageRoute(languageToLoad))
      } else if (languageToLoad !== DEFAULT_LANGUAGE) {
        console.warn(
          languageToLoad,
          "is NOT a supported language; fallback to the default language is loaded and the URL language prefix will be dissapear on the next route change"
        )
        dispatch(localeActions.changeLanguage(DEFAULT_LANGUAGE))
      }
    })
    .then(() => uiActions.fetchTreeData()(dispatch))
    .then(() => {
      // ✅  — finished
      console.groupEnd("🚀 Initialization")
      console.info(
        "%c initialzation took " +
          Math.floor(window.performance.now() - window._PAGE_LOAD_STARTED) +
          " ms",
        "color: #bada55"
      )
      dispatch(configActions.fetchJiraLink())
      dispatch(configActions.fetchFeatureSwitches())
      return dispatch(uiActions.setAppInitStatus({ status: INIT_STATUS.FINISHED }))
    })
    .catch((error) => {
      console.error(error)
      // ! ❌  — if any essential calls fails the application will be notified
      dispatch(
        uiActions.setAppInitStatus({
          error: error.message,
          status: error && error.response ? error.response.status : INIT_STATUS.FAILED,
        })
      )
    })
}

const actions = {
  bootApplication,
  itemActions,
  localeActions,
  uiActions,
  userActions,
  configuratorActions,
  refinerActions,
  documentsActions,
  modalActions,
  draftActions,
  homeActions,
  newsActions,
  opfCommunityActions,
  certificationActions,
  uniqNumberActions,
  statisticsActions,
  certificationActions,
}

export default actions
