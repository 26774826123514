import { PictureFilled } from "@ant-design/icons"
import { Upload } from "antd"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getFileNameFromUrl, removeFileExtension } from "src/components/helpers"
import actions from "src/store/actions"
import { bindActions } from "src/store/utils"

export class UploadPicture extends Component {
  getBase64(img) {
    const reader = new window.FileReader()
    reader.onload = () => this.setState({ thumbUrl: reader.result })
    reader.readAsDataURL(img)
  }

  uploadFile = (e) => {
    const { customUploadRequest } = this.props
    customUploadRequest(e)
  }

  beforeUpload = (file) => {
    this.getBase64(file)
  }

  handleOnChange = (info) => {
    const { onChange, limit } = this.props
    const { file } = info
    const fileList = limit ? info.fileList.slice(-limit) : info.fileList

    const { status } = file
    switch (status) {
      case "done":
        {
          const { media } = file.response
          const fileName = removeFileExtension(getFileNameFromUrl(media.url))
          const fileWithModifiedUploadPercentage = {
            ...file,
            percent: 100,
            url: media.url,
            name: fileName,
            uid: removeFileExtension(media.url),
            status: "done",
            thumbUrl: this.state.thumbUrl,
          }
          const updatedFileList = fileList.map((existingFile) =>
            existingFile.uid === file.uid ? fileWithModifiedUploadPercentage : existingFile
          )
          onChange({ file: fileWithModifiedUploadPercentage, fileList: updatedFileList })
        }
        break
      default:
        onChange(info)
    }
  }

  render() {
    const { value, defaultValue, acceptedFileType } = this.props
    const defaultFileList = defaultValue && defaultValue.fileList ? defaultValue.fileList : []
    const uploadButton = (
      <div>
        <PictureFilled size="large" />
        <div className="ant-upload-text">Upload</div>
      </div>
    )
    return (
      <Upload
        {...this.props}
        accept={acceptedFileType}
        multiple
        listType="picture-card"
        beforeUpload={this.beforeUpload}
        customRequest={this.uploadFile}
        defaultFileList={defaultFileList}
        fileList={value.fileList || defaultFileList}
        showUploadList={false}
        onChange={this.handleOnChange}
      >
        {uploadButton}
      </Upload>
    )
  }
}

UploadPicture.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  customUploadRequest: PropTypes.func,
}
const mapDispatchToProps = bindActions({
  deleteFile: actions.itemActions.deleteFile,
})
export default connect(null, mapDispatchToProps)(UploadPicture)
