import { useCallback, useEffect, useState } from "react"

export default function useKeyPress(targetKey, callback) {
  // State for keeping track of whether key is pressede
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (e) => {
      if (e.key === targetKey) {
        setKeyPressed(true)
      }
    },
    [targetKey]
  )

  // If released key is our target key then set to false
  const upHandler = useCallback(
    (e) => {
      if (e.key === targetKey) {
        setKeyPressed(false)
        callback(e)
      }
    },
    [targetKey, callback]
  )

  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler, { passive: true })
    window.addEventListener("keyup", upHandler, { passive: true })
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler, { passive: true })
      window.removeEventListener("keyup", upHandler, { passive: true })
    }
  }, [upHandler, downHandler]) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}
