import { ASYNC, actionTypes } from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type CertificationsActionTypes = {
  certifications: {
    FETCH_CATEGORIES: AsyncRequestStatus,
    FETCH_CERTIFICATION_BY_ID: AsyncRequestStatus,
    FETCH_CERTIFICATION_BY_SLUG: AsyncRequestStatus,
    CERTIFICATION_UPLOAD_FILE: AsyncRequestStatus,
    CREATE_CATEGORY: AsyncRequestStatus,
    CREATE_CERTIFICATION: AsyncRequestStatus,
    REMOVE_CERTIFICATION: AsyncRequestStatus
  }
}

const types = actionTypes({
  certifications: {
    FETCH_CATEGORIES: ASYNC,
    FETCH_CERTIFICATION_BY_ID: ASYNC,
    FETCH_CERTIFICATION_BY_SLUG: ASYNC,
    CERTIFICATION_UPLOAD_FILE: ASYNC,
    CREATE_CATEGORY: ASYNC,
    CREATE_CERTIFICATION: ASYNC,
    REMOVE_CERTIFICATION: ASYNC
  },
}) as CertificationsActionTypes

export default types.certifications

export interface ICertificationItem {
  id: string;
  opfId: string;
  parentId: string | null;
  slug: string;
  title: string;
  file?: null;
  type: string;
  publishedDate: string;
  publishedBy: string;
}
