import { ASYNC, actionTypes, SYNC } from "../utils"
import { AsyncRequestStatus } from "../utils/reduxTypes"

type NewsActionTypes = {
  news: {
    FETCH_ALL_NEWS: AsyncRequestStatus,
    FETCH_NEWS_BY_ID: AsyncRequestStatus,
    CREATE_NEWS: AsyncRequestStatus
  }
}

const types = actionTypes({
  news: {
    FETCH_ALL_NEWS: ASYNC,
    FETCH_NEWS_BY_ID: ASYNC,
    CREATE_NEWS: ASYNC
  },
}) as NewsActionTypes

export default types.news
