import "draft-js/dist/Draft.css"
import "@draft-js-plugins/static-toolbar/lib/plugin.css"

import {
  BoldButton,
  ItalicButton,
  OrderedListButton,
  UnorderedListButton,
} from "@draft-js-plugins/buttons"
import {
  ContentBlock,
  DefaultDraftBlockRenderMap,
  EditorState,
  RichUtils,
  SelectionState,
  genKey,
} from "draft-js"
import React, { Component, Suspense, lazy } from "react"
import { convertFromHTML, convertToHTML } from "draft-convert"
import Editor from "@draft-js-plugins/editor"
import * as Immutable from "immutable"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import classNames from "classnames"
import createImagePlugin from "@draft-js-plugins/image"
import createToolbarPlugin from "@draft-js-plugins/static-toolbar"
import createToolbarLinkPlugin from "@draft-js-plugins/anchor"
import { debounce } from "lodash"
import keycode from "keycode"
import beautify from "js-beautify"

import EditorImage from "../../EditorImage"
import ImageUploadButton from "../../ImageUploadButton"
import TableBlock from "../TableBlock"

import { draftjsValidationOverride } from "../helper"
import { createRef } from "react"

import "./style.less"

const HTMLEditor = lazy(() => import("../HTMLEditor"))

const contentStateToHTML = convertToHTML({
  blockToHTML: (block) => {
    switch (block.type.toLowerCase()) {
      case "table":
        return <section />
      case "image":
        return <img alt="" />
      case "link":
        /* eslint-disable-next-line */
        return <a />
      default: //
    }
  },
  entityToHTML: (entity, originalText) => {
    switch (entity.type.toLowerCase()) {
      case "table":
        return <table>{ReactHtmlParser(entity.data.element)}</table>
      case "image":
        return <img src={entity.data.src} alt={entity.data.alt} />
      case "link":
        return (
          /* eslint-disable-next-line */
          <a
            href={entity.data.url}
            target={entity.data.url.startsWith(window.location.origin) ? null : "_blank"}
          />
        )
      default: //
    }
    return originalText
  },
})

const contentStatefromHTML = convertFromHTML({
  htmlToBlock: (nodeName, node) => {
    if (nodeName !== "table") {
      if (node.parentElement && node.parentElement.closest("table")) {
        return false
      }
    }
    switch (nodeName) {
      case "table":
        return { type: "table" }
      case "img":
        return { type: "atomic" }
      case "a":
        return { type: "link" }
      default: //
    }
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName !== "table") {
      if (node.parentElement && node.parentElement.closest("table")) {
        return false
      }
    }
    switch (nodeName) {
      case "table":
        return createEntity("TABLE", "IMMUTABLE", { element: node.innerHTML })
      case "img":
        return createEntity("IMAGE", "IMMUTABLE", { src: node.src, alt: node.alt })
      case "a":
        return createEntity("LINK", "MUTABLE", { url: node.href })
      default: //
    }
  },
})

function myBlockRenderer(contentBlock) {
  const type = contentBlock.getType()
  if (type.toLowerCase() === "table") {
    return {
      component: TableBlock,
      editable: false,
    }
  }
  return null
}

export class RichTextEditor extends Component {
  plugins = []
  components = []

  constructor(props) {
    super(props)
    const { imageFeature = false } = props

    const linkPlugin = createToolbarLinkPlugin()
    const { LinkButton } = linkPlugin
    this.structure = [
      { Button: BoldButton },
      { Button: ItalicButton },
      { Button: OrderedListButton },
      { Button: UnorderedListButton },
      { Button: LinkButton },
    ]

    const blockRenderMap = Immutable.Map({
      table: {
        element: "table",
        aliasedElements: ["section"],
      },
    })

    this.extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap)

    let toolbarPlugin = createToolbarPlugin()

    if (imageFeature) {
      const imagePlugin = createImagePlugin({ imageComponent: EditorImage })
      this.structure.push({ Button: ImageUploadButton, props: { imagePlugin } })
      this.plugins.push(imagePlugin)
    }

    this.plugins.push(linkPlugin)
    this.components.push(toolbarPlugin.Toolbar)
    this.plugins.push(toolbarPlugin)

    const editorState = RichTextEditor.getEditorStateFromValue(props.value)
    this.state = {
      editorState,
      markup: props.value,
      inFocus: false,
      htmlMode: false,
      initialEditorStateSet: false,
    }

    this.toolbarRef = createRef()
  }

  saveMarkupInState = debounce((callback) => {
    const currentContent = this.state.editorState.getCurrentContent()
    const markup = contentStateToHTML(currentContent)
    this.setState({ markup })
    callback && callback(markup)
  }, 800)

  saveEditorStateFromMarkup = debounce((markup, callback) => {
    const editorState = RichTextEditor.getEditorStateFromValue(markup)
    this.setState({ editorState })
    callback && callback(markup)
  }, 800)

  handleOnBlur = () => {
    this.saveMarkupInState.cancel()
    this.saveEditorStateFromMarkup.cancel()
    this.handleSetEditorFocus(false)
    this.props.onBlur(contentStateToHTML(this.state.editorState.getCurrentContent()))
  }

  handleOnChange = (editorState) => {
    draftjsValidationOverride(this.toolbarRef)
    return this.setState({ editorState }, () => {
      if (this.state.inFocus) this.saveMarkupInState(this.props.onChange)
    })
  }

  handleOnHTMLEditorChange = (markup = "<p></p>") => {
    return this.setState({ markup }, () =>
      this.saveEditorStateFromMarkup(markup, this.props.onChange)
    )
  }

  handleOnTab = (e) => this.handleOnChange(RichUtils.onTab(e, this.state.editorState, 4))

  handleSetEditorFocus = (isFocused) => this.setState({ inFocus: isFocused })

  handleSwitchView = () => {
    if (this.state.htmlMode) {
      this.setState({ htmlMode: false })
    } else {
      const markup = contentStateToHTML(this.state.editorState.getCurrentContent())
      this.setState({ htmlMode: true, markup: beautify.html(markup) })
    }
  }

  static getEditorStateFromValue(value, editorState = null) {
    if (value) {
      if (editorState)
        return EditorState.push(editorState, contentStatefromHTML(beautify.html(value)), "apply-entity")
      return EditorState.createWithContent(contentStatefromHTML(beautify.html(value)))
    }
    return EditorState.createEmpty()
  }

  static getSelectedBlocks(editorState) {
    const contentState = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()
    const anchorKey = currentSelection.getStartKey()
    const focusKey = currentSelection.getEndKey()
    const isSameBlock = anchorKey === focusKey
    const startingBlock = contentState.getBlockForKey(anchorKey)
    const selectedBlocks = [startingBlock]

    if (!isSameBlock) {
      let blockKey = anchorKey
      while (blockKey !== focusKey) {
        const nextBlock = contentState.getBlockAfter(blockKey)
        selectedBlocks.push(nextBlock)
        blockKey = nextBlock.getKey()
      }
    }

    return selectedBlocks
  }

  onReturn = (event) => {
    const { editorState } = this.state
    if (event.shiftKey) {
      this.handleOnChange(RichUtils.insertSoftNewline(editorState))
      return "handled"
    }
    const selectedBlocks = RichTextEditor.getSelectedBlocks(editorState)
    const tableBlock = selectedBlocks.find((block) => block.type === "table")
    if (tableBlock) {
      const newBlock = new ContentBlock({
        key: genKey(),
        type: "unstyled",
      })
      const nextSelection = new SelectionState({
        anchorKey: newBlock.key,
        anchorOffset: newBlock.getLength(),
        focusKey: newBlock.key,
        focusOffset: newBlock.getLength(),
      })
      const contentState = editorState.getCurrentContent()
      const newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock)
      const newContentState = contentState.merge({ blockMap: newBlockMap })
      const newEditorState = EditorState.push(editorState, newContentState, "insert-fragment")
      const selectionState = EditorState.acceptSelection(newEditorState, nextSelection)
      this.handleOnChange(selectionState)
      return "handled"
    }
    return "not-handled"
  }

  onKeybinding = (event) => {
    if (keycode(event) === "backspace") {
      const { editorState } = this.state
      const contentState = editorState.getCurrentContent()
      const currentSelection = editorState.getSelection()
      const startKey = currentSelection.getStartKey()
      const selectedBlocks = RichTextEditor.getSelectedBlocks(editorState)
      const tableBlock = selectedBlocks.find((block) => block.type === "table")
      if (tableBlock) {
        const newBlock = new ContentBlock({
          key: genKey(),
          type: "unstyled",
        })
        const prevBlock = contentState.getBlockBefore(startKey)
        const blockMap = contentState.getBlockMap()
        const newBlockMap = blockMap.remove(tableBlock.key).set(newBlock.key, newBlock)
        const newContentState = contentState.merge({ blockMap: newBlockMap })
        const nextSelection = prevBlock
          ? new SelectionState({
              anchorKey: prevBlock.key,
              anchorOffset: prevBlock.getLength(),
              focusKey: prevBlock.key,
              focusOffset: prevBlock.getLength(),
            })
          : SelectionState.createEmpty(newBlock.key)
        const newEditorState = EditorState.push(editorState, newContentState, "remove-range")
        const selectionState = EditorState.acceptSelection(newEditorState, nextSelection)
        this.handleOnChange(selectionState)
        return "handled"
      }
    }
    if (keycode(event) === "tab") this.handleOnTab(event)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      const editorState = RichTextEditor.getEditorStateFromValue(this.props.value, this.state.editorState)
      this.setState({editorState})
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initialEditorStateSet) return null
    const editorState = RichTextEditor.getEditorStateFromValue(props.value, state.editorState)
    if (state.itemKey !== props.itemKey) {
      return { editorState, itemKey: props.itemKey }
    }
    if (props.value !== "") {
      return { editorState, initialEditorStateSet: true }
    }
    return null
  }

  render() {
    const { editorState, htmlMode, markup, inFocus } = this.state
    const { htmlModeFeature = false } = this.props
    const rteClasses = classNames(
      "rich-text-editor",
      "rich-text-editor--extended",
      { "rich-text-editor--focus": inFocus },
      this.props.className
    )

    if (!htmlModeFeature) {
      return (
        <div className={rteClasses}>
          <div className="rich-text-editor__toolbar">
            {this.components.map((Component, index) => (
              <Component key={index}>
                {(externalProps) =>
                  this.structure.map(({ Button, props }, index) => (
                    <Button key={index} {...externalProps} {...props} />
                  ))
                }
              </Component>
            ))}
          </div>
          <Editor
            {...this.props}
            handleReturn={this.onReturn}
            plugins={this.plugins}
            onFocus={() => this.handleSetEditorFocus(true)}
            onBlur={this.handleOnBlur}
            blockRenderMap={this.extendedBlockRenderMap}
            blockRendererFn={myBlockRenderer}
            keyBindingFn={this.onKeybinding}
            onChange={this.handleOnChange}
            editorState={editorState}
          />
        </div>
      )
    }

    return (
      <div className={rteClasses}>
        <Suspense fallback={null}>
          {htmlModeFeature && htmlMode ? (
            <HTMLEditor value={markup} onValueChange={this.handleOnHTMLEditorChange} />
          ) : (
            <>
              <div className="rich-text-editor__toolbar" ref={this.toolbarRef}>
                {this.components.map((Component, index) => (
                  <Component key={index}>
                    {(externalProps) =>
                      this.structure.map(({ Button, props }, index) => (
                        <Button key={index} {...externalProps} {...props} />
                      ))
                    }
                  </Component>
                ))}
              </div>
              <Editor
                {...this.props}
                handleReturn={this.onReturn}
                plugins={this.plugins}
                onFocus={() => this.handleSetEditorFocus(true)}
                blockRenderMap={this.extendedBlockRenderMap}
                blockRendererFn={myBlockRenderer}
                keyBindingFn={this.onKeybinding}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
                editorState={editorState}
              />
            </>
          )}
          {htmlModeFeature ? (
            <div className="rich-text-editor__switch" onClick={this.handleSwitchView}>
              {htmlMode ? "View Content" : "View Html"}
            </div>
          ) : null}
        </Suspense>
      </div>
    )
  }
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  imageFeature: PropTypes.bool,
  htmlModeFeature: PropTypes.bool,
  value: PropTypes.any,
}
export default RichTextEditor
